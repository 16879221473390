// MUI
import {
  IconButton,
  Typography,
  Box,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  CircularProgress,
  Paper,
} from '@mui/material';
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineContent,
  TimelineConnector,
  TimelineOppositeContent,
  TimelineDot,
  timelineDotClasses,
  timelineOppositeContentClasses,
  timelineContentClasses,
  timelineItemClasses,
} from '@mui/lab';
import { Delete, Edit, MoreVert } from '@mui/icons-material';
// React
import { useState } from 'react';
import { JournalForm, NewItem } from 'components';
// Features
import { useDeleteJournalEntryMutation } from 'features/journal/journalAPI.jsx';
// Custom Hooks
import { useGetJournalsByCycleId } from 'features/journal/hooks';
// Helpers
import moment from 'moment';
import { customToast } from 'helpers/customToast';

function JournalList() {
  // React Query
  const { data: journals, isLoading, isFetching } = useGetJournalsByCycleId();

  const [deleteJournalEntry] = useDeleteJournalEntryMutation();

  // Component State
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [activeJournalId, setActiveJournalId] = useState(null);
  const [editing, setEditing] = useState(false);
  const [creating, setCreating] = useState(false);

  const handleOpenNavMenu = (e) => {
    setAnchorElNav(e.currentTarget);
    setActiveJournalId(e.currentTarget.id);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
    setActiveJournalId(null);
  };

  const handleEdit = (journal_id) => {
    setActiveJournalId(journal_id);
    setCreating(false);
    setEditing(true);
  };

  const handleCreate = () => {
    setEditing(false);
    setCreating(true);
  };

  const handleDelete = (journal_id) => {
    !journal_id && customToast('error', 'No journal selected');
    setAnchorElNav(null);
    deleteJournalEntry(journal_id);
  };

  const cancelForm = () => {
    setEditing(false);
    setCreating(false);
  };

  if (isLoading || isFetching) {
    return <></>;
  }

  return (
    <Box>
      <Timeline
        sx={{
          [`& .${timelineOppositeContentClasses.root}`]: {
            flex: '0 1 80px',
            pl: 1,
            color: 'text.secondary',
          },
          [`& .${timelineContentClasses.root}`]: {
            flex: 1,
            pb: 0,
            mb: 0.5,
          },
          [`& .${timelineItemClasses.root}`]: {
            minHeight: 'auto',
          },
          [`& .${timelineDotClasses.root}`]: {
            mb: 0.5,
          },
          p: 0,
          m: 0,
        }}>
        {journals.map((journal) => {
          return editing && journal.id === activeJournalId ? (
            <JournalForm
              key={journal.id + '_timeline_item_editing'}
              editing={editing}
              journal={journal}
              cancelForm={cancelForm}
            />
          ) : (
            <TimelineItem key={journal.id + '_timeline_item'}>
              <TimelineOppositeContent>
                {moment(Date.parse(journal.date)).format('MMM Do')}
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineDot />
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                <Paper
                  sx={{
                    p: 0.3,
                    pl: 1,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}>
                  <Typography
                    sx={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      display: '-webkit-box',
                      WebkitLineClamp: '3',
                      WebkitBoxOrient: 'vertical',
                      wordBreak: 'break-all',
                    }}>
                    {journal.text}
                  </Typography>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'flex-end',
                    }}>
                    <IconButton
                      id={journal.id}
                      onClick={() => handleEdit(journal.id)}>
                      <Edit fontSize='small' />
                    </IconButton>
                    <IconButton id={journal.id} onClick={handleOpenNavMenu}>
                      <MoreVert fontSize='small' />
                    </IconButton>
                  </Box>
                </Paper>
              </TimelineContent>
            </TimelineItem>
          );
        })}
        {creating ? (
          <JournalForm cancelForm={cancelForm} />
        ) : (
          <TimelineItem>
            <TimelineOppositeContent>Today</TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot variant='outlined' />
            </TimelineSeparator>
            <TimelineContent>
              <NewItem
                handleCreate={handleCreate}
                title='Add Journal Entry'
                has_items={journals.length}
              />
            </TimelineContent>
          </TimelineItem>
        )}
      </Timeline>
      <Menu
        id='menu-appbar'
        anchorEl={anchorElNav}
        keepMounted
        open={Boolean(anchorElNav)}
        onClose={handleCloseNavMenu}>
        <MenuItem
          onClick={() => {
            handleDelete(activeJournalId);
          }}>
          <ListItemIcon>
            <Delete fontSize='small' />
          </ListItemIcon>
          <ListItemText>Delete</ListItemText>
        </MenuItem>
      </Menu>
    </Box>
  );
}

export default JournalList;
