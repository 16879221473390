// React
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
// MUI
import { Box, Stack, Grid, Button, useTheme } from '@mui/material';
// Components
import {} from 'components';
// Features
import {
  selectDashboardInfo,
  setCycleMenu,
} from 'features/dashboard/dashboardSlice';

const CycleMenu = () => {
  const dispatch = useDispatch();
  const { menu_selected, menu_items } = useSelector(selectDashboardInfo);
  const theme = useTheme();

  return (
    <Box
      sx={{
        maxWidth: '100vw',
        [theme.breakpoints.down('sm')]: {
          pl: 1,
          pr: 1,
        },
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'center',
      }}>
      <Grid container justifyContent='center' spacing={1}>
        {menu_items.map((name, i) => (
          <Grid
            item
            xs={6}
            sm='auto'
            key={name + '_grid_' + i}
            sx={{ maxWidth: '100vw' }}>
            <Button
              key={name + '_' + i}
              name={name}
              variant={name === menu_selected ? 'contained' : 'outlined'}
              onClick={() => dispatch(setCycleMenu(name))}
              sx={{ width: '100%' }}>
              {name}
            </Button>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default CycleMenu;
