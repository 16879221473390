import { BLOOD_UNITS } from 'constants';

// max_levels is an object with the highest value for each blood marker
//    ex: { T: 1000 }
//    This is used to scale the y-axis for each marker according to it's max value
// bloods_by_marker is an object with an array of objects for each blood marker
//    each object in the array has a date and the value for that marker
//    ex: { date: '2021-01-01', T: 1000 } for TSH
//    This keeps the data set for each marker separate so that we can
//    easily map over each marker and create a line for each one
// hidden_results is an object with the id of each blood result that has been hidden
//    ex: { 1: true, 2: true }
//    This is used to filter out blood results that have been hidden
// hidden_markers is an object with the name of each blood marker that has been hidden
//    ex: { T: true, TSH: true }
//    This is used to filter out blood markers that have been hidden
export const bloodsPrepper = (bloods, hidden_results, hidden_markers) => {
  if (bloods?.length) {
    let max_levels = {};
    let bloods_by_marker = {};
    bloods.forEach((blood, i) => {
      if (!(blood.id in hidden_results)) {
        Object.keys(BLOOD_UNITS).forEach((marker) => {
          if (blood[marker] && !(marker in hidden_markers)) {
            if (bloods_by_marker[marker]) {
              bloods_by_marker[marker].push({
                date: blood.date,
                [marker]: blood[marker],
              });
            } else {
              bloods_by_marker[marker] = [
                { date: blood.date, [marker]: blood[marker] },
              ];
            }
            if (marker in max_levels) {
              if (blood[marker] > max_levels[marker]) {
                max_levels[marker] = blood[marker];
              }
            } else {
              max_levels[marker] = blood[marker];
            }
          }
        });
      }
    });
    return [max_levels, bloods_by_marker];
  }
  return [{}, {}];
};
