// React
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { useEffect } from 'react';
// RTK Query API
import {
  useGetCycleByIdQuery,
  useUpdateCycleMutation,
} from 'features/cycles/cyclesAPI';
// Redux
import { updateCycle, selectCycleInfo } from 'features/cycles/cyclesSlice';

export const useGetCycleById = () => {
  const { active_cycle_id } = useSelector(selectCycleInfo);
  const is_authenticated = useSelector((state) => state.auth.is_authenticated);
  const no_auth_cycle = useSelector((state) => state.cycles.no_auth_cycle);

  const {
    data: cycle,
    isSuccess,
    isLoading,
    isFetching,
  } = useGetCycleByIdQuery(active_cycle_id, {
    skip: !is_authenticated || !active_cycle_id,
  });

  return {
    data: is_authenticated ? cycle : no_auth_cycle,
    isSuccess: is_authenticated ? isSuccess : true,
    isLoading,
    isFetching,
  };
};

export const useUpdateCycle = () => {
  const is_authenticated = useSelector((state) => state.auth.is_authenticated);

  const [mutate] = useUpdateCycleMutation();
  const dispatch = useDispatch();

  const updateCycleWrapper = async (cycle_data) => {
    if (is_authenticated) {
      // Return the RTK Query mutation
      return mutate(cycle_data);
    } else {
      // Dispatch the update cycle action
      dispatch(
        updateCycle({
          id: cycle_data.id,
          title: cycle_data.title,
          start_date:
            typeof cycle_data.start_date === 'string'
              ? cycle_data.start_date
              : cycle_data.start_date.format('YYYY-MM-DD HH:mm:ssZ'),
          weeks: cycle_data.weeks,
          notes: cycle_data.notes,
        })
      );
      return Promise.resolve({ data: cycle_data });
    }
  };

  return [updateCycleWrapper];
};
