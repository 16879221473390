// React
import { useSelector } from 'react-redux';
// RTK Query API
import { useGetBloodsByCycleIdQuery } from './bloodsAPI';
// Redux
import { selectCycleInfo } from 'features/cycles/cyclesSlice';

// Custom Hook wrapper around useGetBloodsByCycleIdQuery
export const useGetBloodsByCycleId = () => {
  const { active_cycle_id } = useSelector(selectCycleInfo);
  const is_authenticated = useSelector((state) => state.auth.is_authenticated);
  const no_auth_bloods = useSelector((state) => state.bloods.no_auth_bloods);

  const {
    data: bloods,
    isSuccess,
    isLoading,
    isFetching,
  } = useGetBloodsByCycleIdQuery(active_cycle_id, {
    skip: !is_authenticated || !active_cycle_id,
  });

  return {
    data: is_authenticated ? bloods : no_auth_bloods,
    isSuccess: is_authenticated ? isSuccess : true,
    isLoading,
    isFetching,
  };
};
