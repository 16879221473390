import { createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import { customToast } from '../../helpers/customToast';
import { dosesAPI } from './dosesAPI';

const initialState = {
  status: 'idle',
  message: '',
  no_auth_doses: [],
};

export const drugSlice = createSlice({
  name: 'drugs',
  initialState,
  reducers: {
    reset: (state) => initialState,
    createDosage: (state, action) => {
      state.no_auth_doses.push(action.payload);
      window.dataLayer.push({
        event: 'no_auth_compound_created',
        category: 'CRUD',
        table: 'Compounds',
        action: 'CREATE',
        auth: false,
      });
    },
    updateDosage: (state, action) => {
      const index = state.no_auth_doses.findIndex(
        (dose) => dose.id === action.payload.id
      );
      delete action.payload.updated_at;
      state.no_auth_doses[index] = action.payload;
      window.dataLayer.push({
        event: 'no_auth_compound_updated',
        category: 'CRUD',
        table: 'Compounds',
        action: 'UPDATE',
        auth: false,
      });
    },
    deleteDosage: (state, action) => {
      state.no_auth_doses = state.no_auth_doses.filter(
        (dose) => dose.id !== action.payload
      );
      window.dataLayer.push({
        event: 'no_auth_compound_deleted',
        category: 'CRUD',
        table: 'Compounds',
        action: 'DELETE',
        auth: false,
      });
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(dosesAPI.endpoints.createDosage.matchFulfilled, () => {
        customToast('success', 'Dosage created successfully');
        window.dataLayer.push({
          event: 'compound_created',
          category: 'CRUD',
          table: 'Compounds',
          action: 'CREATE',
          auth: true,
        });
      })
      .addMatcher(dosesAPI.endpoints.updateDosage.matchFulfilled, () => {
        customToast('success', 'Dosage updated successfully');
        window.dataLayer.push({
          event: 'compound_updated',
          category: 'CRUD',
          table: 'Compounds',
          action: 'UPDATE',
          auth: true,
        });
      })
      .addMatcher(dosesAPI.endpoints.deleteDosage.matchFulfilled, () => {
        customToast('success', 'Dosage deleted successfully');
        window.dataLayer.push({
          event: 'compound_deleted',
          category: 'CRUD',
          table: 'Compounds',
          action: 'DELETE',
          auth: true,
        });
      });
  },
});

export const { reset, createDosage, updateDosage, deleteDosage } =
  drugSlice.actions;
export default drugSlice.reducer;
