import { createTheme } from '@mui/material/styles';

const primary_main = '#222630';
const secondary_main = '#16a2ed';
const background_default = '#f8f8f8';

export const theme = createTheme({
  palette: {
    primary: {
      main: primary_main,
    },
    secondary: {
      main: secondary_main,
    },
    background: {
      default: background_default,
    },
  },
  typography: {
    fontSize: 13,
  },
});

export const dark_theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: background_default,
    },
    secondary: {
      main: secondary_main,
    },
    background: {
      default: primary_main,
    },
  },
  typography: {
    fontSize: 13,
  },
});
