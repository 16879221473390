import ColorHash from 'color-hash';
import TinyColor from 'tinycolor2';

const lighten_amt = 15;

// Original ColorHash options
export const colorHash2 = new ColorHash({
  hue: [
    { min: 180, max: 300 },
    { min: 0, max: 210 },
    { min: 0, max: 300 },
  ],
  saturation: [0.5, 0.7],
});

export const colorHash = new ColorHash({
  hue: [
    { min: 40, max: 360 },
    { min: 0, max: 210 },
    { min: 0, max: 300 },
  ],
  saturation: [0.5, 0.7],
});

export const lighterColorHash = (str) => {
  return TinyColor(colorHash.hex(str)).lighten(lighten_amt).toHexString();
};

export const desaturateColor = (color, amt) => {
  return TinyColor(color).desaturate(amt).toHexString();
};

export const variableLightColorHash = (str, amt) => {
  return TinyColor(colorHash.hex(str)).lighten(amt).toHexString();
};
