// React
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { useEffect } from 'react';
// RTK Query API
import {
  useGetDosesByCycleIdQuery,
  useCreateDosageMutation,
  useDeleteDosageMutation,
  useUpdateDosageMutation,
} from 'features/doses/dosesAPI';
// Redux
import { selectCycleInfo } from 'features/cycles/cyclesSlice';
import {
  createDosage,
  updateDosage,
  deleteDosage,
} from 'features/doses/dosesSlice';
// Helpers
import { v4 as uuid_v4 } from 'uuid';

// Custom Hook wrapper around useGetDoesByCycleIdQuery
export const useGetDosesByCycleId = () => {
  const { active_cycle_id } = useSelector(selectCycleInfo);
  const is_authenticated = useSelector((state) => state.auth.is_authenticated);
  const no_auth_doses = useSelector((state) => state.drugs.no_auth_doses);

  const {
    data: doses,
    isSuccess,
    isLoading,
    isFetching,
  } = useGetDosesByCycleIdQuery(active_cycle_id, {
    skip: !is_authenticated || !active_cycle_id,
  });

  return {
    data: is_authenticated ? doses : no_auth_doses,
    isSuccess: is_authenticated ? isSuccess : true,
    isLoading,
    isFetching,
  };
};

// Custom Hook wrapper around useCreateDosageMutation
export const useCreateDosage = () => {
  const is_authenticated = useSelector((state) => state.auth.is_authenticated);

  const [mutate] = useCreateDosageMutation();
  const dispatch = useDispatch();

  const createDosageWrapper = async (dosage_data) => {
    if (is_authenticated) {
      // Return the RTK Query mutation
      return mutate(dosage_data);
    } else {
      // Dispatch the create dosage action
      dispatch(
        createDosage({
          id: uuid_v4(),
          name: dosage_data.name,
          ester: dosage_data.ester,
          frequency: dosage_data.frequency,
          dose: dosage_data.dose,
          date:
            typeof dosage_data.date === 'string'
              ? dosage_data.date
              : dosage_data.date.format('YYYY-MM-DD HH:mm:ssZ'),
          start_wk: dosage_data.start_wk,
          stop_wk: dosage_data.stop_wk,
        })
      );
      return Promise.resolve({ data: dosage_data });
    }
  };

  return [createDosageWrapper];
};

// Custom Hook wrapper around useUpdateDosageMutation
export const useUpdateDosage = () => {
  const is_authenticated = useSelector((state) => state.auth.is_authenticated);

  const [mutate] = useUpdateDosageMutation();
  const dispatch = useDispatch();

  const updateDosageWrapper = async (dosage_data) => {
    if (is_authenticated) {
      // Return the RTK Query mutation
      return mutate(dosage_data);
    } else {
      // Dispatch the update dosage action
      dispatch(
        updateDosage({
          ...dosage_data,
          date:
            typeof dosage_data.date === 'string'
              ? dosage_data.date
              : dosage_data.date.format('YYYY-MM-DD HH:mm:ssZ'),
        })
      );
      return Promise.resolve({ data: dosage_data });
    }
  };

  return [updateDosageWrapper];
};

// Custom Hook wrapper around useDeleteDosageMutation
export const useDeleteDosage = () => {
  const is_authenticated = useSelector((state) => state.auth.is_authenticated);

  const [mutate] = useDeleteDosageMutation();
  const dispatch = useDispatch();

  const deleteDosageWrapper = async (dosage_id) => {
    if (is_authenticated) {
      // Return the RTK Query mutation
      return mutate(dosage_id);
    } else {
      // Dispatch the delete dosage action
      dispatch(deleteDosage(dosage_id));
      return Promise.resolve({ data: dosage_id });
    }
  };

  return [deleteDosageWrapper];
};
