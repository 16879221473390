import React from 'react';
import { lighterColorHash } from 'helpers/colorHash';
import { GlyphSeries } from '@visx/xychart';
import moment from 'moment';

const AncillaryLines = ({
  cycle_start_date,
  max_release,
  max_ancillary_levels = {},
  graph_data_by_ancillary = {},
}) => {
  const getDaysFromStart = (date) => {
    const days_diff = moment(date)
      .startOf('day')
      .diff(moment(cycle_start_date).startOf('day'), 'days');
    return days_diff;
  };

  const getAncillaryLevel = (ancillary, name) => {
    return ancillary[name]
      ? ((max_release || max_ancillary_levels[name]) /
          max_ancillary_levels[name]) *
          ancillary[name]
      : 'null';
  };

  if (Object.keys(graph_data_by_ancillary)?.length === 0) return null;

  return (
    <>
      {Object.keys(graph_data_by_ancillary).map((name) => (
        <React.Fragment key={name + '_ancillaries_graph_fragment'}>
          <GlyphSeries
            key={name + '_glyph'}
            dataKey={name}
            data={graph_data_by_ancillary[name]}
            xAccessor={(ancillary) =>
              getDaysFromStart(ancillary.date).toFixed(1)
            }
            yAccessor={(ancillary) =>
              getAncillaryLevel(ancillary, name.split('_')[0])
            }
            colorAccessor={(ancillary) => lighterColorHash(name.split('_')[0])}
            strokeWidth={2}
          />
        </React.Fragment>
      ))}
    </>
  );
};

export default AncillaryLines;
