// MUI
import {
  Button,
  TextField,
  FormControl,
  Typography,
  IconButton,
  Chip,
  Box,
  Select,
  Grid,
  Menu,
  MenuItem,
  InputLabel,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  ListItemIcon,
  ListItemText,
  useMediaQuery,
} from '@mui/material';
import {
  Edit,
  Check,
  Close,
  Delete,
  MoreVert,
  Notes,
} from '@mui/icons-material';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
// React
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { useState, useEffect } from 'react';
// Features
import {
  selectCycleInfo,
  toggleEditing,
  setEditing,
  toggleNotesShowing,
} from 'features/cycles/cyclesSlice';
import { openSignUp } from 'features/auth/authSlice';
import { useGetUserQuery } from 'features/auth/authAPI';
import {
  useCreateCycleMutation,
  useDeleteCycleMutation,
} from 'features/cycles/cyclesAPI';
import { useGetCycleById, useUpdateCycle } from 'features/cycles/hooks';
// Other
import { CycleMenu } from 'components';
import moment from 'moment';
import { customToast } from 'helpers/customToast';

const initial_form_state = {
  title: '',
  start_date: moment(),
  weeks: '',
  notes: '',
};

const [xs, sm] = [12, 4];

const CycleInfo = () => {
  // Redux
  const { creating, editing, notes_showing } = useSelector(selectCycleInfo);
  const dispatch = useDispatch();

  // React Query
  const { data: user, isLoading: userLoading } = useGetUserQuery();
  const { id: user_id } = user || {};
  const {
    data: cycle,
    isSuccess,
    isLoading: cycleLoading,
    isFetching: cycleFetching,
  } = useGetCycleById();
  const {
    id: cycle_id,
    title,
    start_date,
    weeks,
    notes,
  } = isSuccess ? cycle : { ...initial_form_state, id: '' };
  const [createCycle] = useCreateCycleMutation();
  const [updateCycle] = useUpdateCycle();
  const [deleteCycle] = useDeleteCycleMutation();

  // Component State
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [deleteCycleDialogOpen, setDeleteCycleDialogOpen] = useState(false);
  const is_small_screen = useMediaQuery((theme) =>
    theme.breakpoints.down('sm')
  );
  const is_medium_screen = useMediaQuery((theme) =>
    theme.breakpoints.down('md')
  );
  const [form_data, setFormData] = useState(
    title ? { title, start_date, weeks, notes } : initial_form_state
  );

  useEffect(() => {
    if (creating) {
      setFormData(initial_form_state);
    } else {
      setFormData({ title, start_date, weeks, notes });
    }
  }, [editing]);

  const handleFormDataChange = (e) => {
    if (!user && e.target.name === 'weeks' && e.target.value > 40) {
      dispatch(openSignUp('greater_than_40_weeks'));
      customToast(
        'info',
        'Please Sign Up or Login to Create a Cycle longer than 40 weeks.',
        5000
      );
      return;
    }
    setFormData({ ...form_data, [e.target.name]: e.target.value });
  };

  const handleStartDateChange = (date) => {
    if (!user) {
      dispatch(openSignUp('change_start_date'));
      customToast(
        'info',
        'Please Sign Up or Login to Create a Cycle and change the date.',
        5000
      );
      return;
    }
    setFormData({ ...form_data, start_date: date });
  };

  const onDeleteCycle = () => {
    setDeleteCycleDialogOpen(false);
    setAnchorElNav(null);
    if (!user) {
      dispatch(openSignUp('delete_cycle'));
      customToast(
        'info',
        'Please Sign Up or Login to save and delete Cycles.',
        5000
      );
      return;
    }
    deleteCycle(cycle_id);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (
      creating &&
      form_data.title &&
      form_data.start_date &&
      form_data.weeks
    ) {
      createCycle({ ...form_data, user_id });
    } else if (form_data.title && form_data.start_date && form_data.weeks) {
      if (shallowEqual(form_data, { title, start_date, weeks, notes })) {
        customToast('info', 'No changes');
        return;
      }
      updateCycle({ ...form_data, updated_at: moment(), id: cycle_id });
    } else {
      customToast('error', 'Please fill all the fields');
    }
  };

  if (userLoading || cycleLoading || cycleFetching) {
    return <></>;
  }

  if (creating || editing) {
    return (
      <form onSubmit={onSubmit}>
        <Grid
          container
          spacing={1}
          direction='row'
          alignItems={'baseline'}
          justifyContent={is_medium_screen ? 'stretch' : 'center'}
          p={1}>
          <Grid item xs={xs} md alignSelf={'center'}>
            <FormControl sx={{ width: '100%' }}>
              <TextField
                required
                size='small'
                name='title'
                value={form_data.title}
                id='standard-basic'
                variant='standard'
                placeholder='Name this Cycle'
                onChange={handleFormDataChange}
                sx={{ mr: 1, flex: 1 }}
                inputProps={{ style: { fontSize: '1.3rem' } }}
                disabled={user ? false : true}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm md='auto'>
            <Box
              sx={{
                display: 'flex',
                flex: 1,
                justifyContent: is_small_screen ? 'center' : '',
              }}>
              <FormControl sx={{ flex: 1, minWidth: 150, m: 1, ml: 0 }}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    required
                    name='start_date'
                    value={form_data.start_date}
                    label='Start Date'
                    inputFormat='MM/DD/YYYY'
                    onChange={(value) => handleStartDateChange(value)}
                    renderInput={(params) => (
                      <TextField size='small' {...params} />
                    )}
                  />
                </LocalizationProvider>
              </FormControl>
              <FormControl sx={{ minWidth: 90, m: 1 }} size='small'>
                <InputLabel id='weeks-input-label'>Weeks</InputLabel>
                <Select
                  autoWidth
                  required
                  labelId='weeks-input-label'
                  name='weeks'
                  value={form_data.weeks}
                  label='Weeks'
                  onChange={handleFormDataChange}>
                  {Array.from({ length: 100 }, (_, i) => i + 1).map((i) => (
                    <MenuItem key={i} value={i}>
                      {i}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Grid>
          <Grid item xs sm='auto' alignSelf={'center'}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: is_small_screen ? 'center' : 'flex-end',
                alignItems: 'flex-end',
              }}>
              <IconButton
                variant='contained'
                onClick={() => {
                  dispatch(setEditing(false));
                  creating
                    ? setFormData(initial_form_state)
                    : setFormData({ title, start_date, weeks });
                }}>
                <Close />
              </IconButton>
            </Box>
          </Grid>
          <Grid item xs sm='auto' alignSelf={'center'}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: is_small_screen ? 'center' : 'flex-end',
                alignItems: 'flex-end',
              }}>
              <Button variant={false ? 'outlined' : 'contained'} type='submit'>
                <Check />
              </Button>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <FormControl sx={{ width: '100%' }}>
              <TextField
                name='notes'
                size='small'
                placeholder='Add notes (optional)'
                multiline
                minRows={3}
                maxRows={8}
                value={form_data.notes}
                onChange={handleFormDataChange}
                sx={{ width: '100%' }}
                disabled={user ? false : true}
              />
            </FormControl>
          </Grid>
        </Grid>
      </form>
    );
  }

  return (
    <Box
      alignItems={'center'}
      justifyContent={'center'}
      p={1}
      sx={{ width: '100%', display: 'flex', maxWidth: '100%' }}>
      <Grid
        container
        spacing={1}
        direction='row'
        alignItems={'center'}
        justifyContent={'center'}
        p={1}>
        <Grid item xs={xs} sm='auto'>
          <Box
            sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              maxWidth: '90vw',
            }}>
            <Typography
              variant='h5'
              align={is_small_screen ? 'center' : 'right'}>
              {title}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={'auto'} sm='auto'>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Chip
              sx={{ m: 0.5 }}
              label={`${moment(start_date).format('LL')}`}
            />
            <Chip sx={{ m: 0.5 }} label={`${weeks} Weeks`} />
          </Box>
        </Grid>
        <Grid item xs='auto' sm='auto'>
          <Box
            sx={{
              display: 'flex',
              justifyContent: is_small_screen ? 'flex-end' : 'center',
            }}>
            <IconButton
              variant='contained'
              onClick={() => dispatch(toggleEditing())}>
              <Edit />
            </IconButton>
          </Box>
        </Grid>
        {notes && (
          <Grid item xs='auto' sm='auto'>
            <Box
              sx={{
                display: 'flex',
                justifyContent: is_small_screen ? 'flex-end' : 'center',
              }}>
              <IconButton
                variant='contained'
                onClick={() => dispatch(toggleNotesShowing())}>
                <Notes />
              </IconButton>
            </Box>
          </Grid>
        )}
        <Grid item xs='auto' sm='auto'>
          <Box
            sx={{
              display: 'flex',
              justifyContent: is_small_screen ? 'flex-end' : 'center',
            }}>
            <IconButton
              variant='contained'
              onClick={(e) => setAnchorElNav(e.currentTarget)}>
              <MoreVert />
            </IconButton>
          </Box>
        </Grid>
        <Grid item xs='auto' sm='auto'>
          <Box
            sx={{
              display: 'flex',
              justifyContent: is_small_screen ? 'flex-end' : 'center',
            }}>
            <CycleMenu />
          </Box>
        </Grid>
        {notes && notes_showing && (
          <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
            <Box
              sx={{
                width: '100%',
                p: 1,
                m: 0,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                maxWidth: '90vw',
              }}>
              <Typography variant='subtitle1' align={'center'}>
                {notes}
              </Typography>
            </Box>
          </Grid>
        )}
      </Grid>

      <Menu
        anchorEl={anchorElNav || null}
        keepMounted
        open={Boolean(anchorElNav)}
        onClose={() => setAnchorElNav(null)}>
        <MenuItem
          onClick={() => {
            setDeleteCycleDialogOpen(true);
          }}
          sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <ListItemIcon>
            <Delete fontSize='small' />
          </ListItemIcon>
          <ListItemText>Delete</ListItemText>
        </MenuItem>
      </Menu>

      <Dialog
        open={deleteCycleDialogOpen}
        onClose={() => {
          setAnchorElNav(null);
          setDeleteCycleDialogOpen(false);
        }}
        maxWidth='sm'
        fullWidth>
        <DialogTitle>Delete Cycle</DialogTitle>
        <DialogContent>
          Are you sure? This will permanently delete the cycle and all of the
          compounds you've added to it.
        </DialogContent>
        <DialogActions>
          <Button
            variant='outlined'
            onClick={() => {
              setDeleteCycleDialogOpen(false);
              setAnchorElNav(null);
            }}>
            Cancel
          </Button>
          <Button color='error' variant='contained' onClick={onDeleteCycle}>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default CycleInfo;
