// React
import { useEffect } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
// Internal
import { Header, Dashboard } from './components';
// MUI
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { theme, dark_theme } from './lib/theme';
// Supabase
import { supabase } from './lib/api';
// Helpers
import { customToast } from './helpers/customToast';
// React Query
import { useGetUserQuery } from './features/auth/authAPI';
// Google Tag Manager
import initializeGoogleTagManager from 'helpers/GoogleTagManager';
initializeGoogleTagManager();

function App() {
  const { data: user, isLoading, isFetching } = useGetUserQuery();

  useEffect(() => {
    const { data: authListener } = supabase.auth.onAuthStateChange(
      async (event, session) => {
        if (event === 'PASSWORD_RECOVERY') {
          const newPassword = prompt(
            'What would you like your new password to be?'
          );
          const { data, error } = await supabase.auth.updateUser({
            password: newPassword,
          });

          if (data) customToast('success', 'Password updated successfully!');
          if (error) customToast('There was an error updating your password.');
        }
      }
    );

    return () => {
      authListener?.subscription?.unsubscribe();
    };
  }, []);

  return (
    // <ThemeProvider theme={user ? theme : dark_theme}>
    <ThemeProvider theme={true ? theme : dark_theme}>
      <CssBaseline />
      <Router>
        <Header />
        <Routes>
          <Route path='*' element={<Dashboard />} />
        </Routes>
        <ToastContainer />
      </Router>
    </ThemeProvider>
  );
}

export default App;
