import moment from 'moment';
import { v4 as uuid_v4 } from 'uuid';

export const STEP = 0.5;

export const DRUG_FACTS = {
  Compounds: {
    Testosterone: {
      has_esters: true,
      'Testosterone-Propionate': {
        name: 'Testosterone Propionate',
        nickname: 'Test-Prop',
        half_life_days: 0.8,
        dose_percentage_active: 0.8,
      },
      'Testosterone-Phenylpropionate': {
        name: 'Testosterone Phenylpropionate',
        nickname: 'Test-Phenyl',
        half_life_days: 1.5,
        dose_percentage_active: 0.66,
      },
      'Testosterone-Isocaproate': {
        name: 'Testosterone Isocaproate',
        nickname: 'Test-Iso',
        half_life_days: 4.0,
        dose_percentage_active: 0.72,
      },
      'Testosterone-Enanthate': {
        name: 'Testosterone Enanthate',
        nickname: 'Test-E',
        half_life_days: 4.5,
        dose_percentage_active: 0.7,
      },
      'Testosterone-Cypionate': {
        name: 'Testosterone Cypionate',
        nickname: 'Test-Cyp',
        half_life_days: 5.0,
        dose_percentage_active: 0.69,
      },
      'Testosterone-Decanoate': {
        name: 'Testosterone Decanoate',
        nickname: 'Test-Deca',
        half_life_days: 7.5,
        dose_percentage_active: 0.62,
      },
      'Testosterone-Undecanoate': {
        name: 'Testosterone Undecanoate',
        nickname: 'Test-U',
        half_life_days: 20.9,
        dose_percentage_active: 0.61,
      },
    },
    Trenbolone: {
      has_esters: true,
      'Trenbolone-Acetate': {
        name: 'Trenbolone Acetate',
        nickname: 'Tren-Ace',
        half_life_days: 1.0,
        dose_percentage_active: 0.87,
      },
      'Trenbolone-Enanthate': {
        name: 'Trenbolone Enanthate',
        nickname: 'Tren-E',
        half_life_days: 4.5,
        dose_percentage_active: 0.7,
      },
    },
    Masteron: {
      has_esters: true,
      'Masteron-Propionate': {
        name: 'Masteron Propionate',
        nickname: 'Mast-Prop',
        half_life_days: 0.8,
        dose_percentage_active: 0.8,
      },
      'Masteron-Enanthate': {
        name: 'Masteron Enanthate',
        nickname: 'Mast-E',
        half_life_days: 4.5,
        dose_percentage_active: 0.7,
      },
    },
    Nandrolone: {
      has_esters: true,
      'Nandrolone-Phenylpropionate': {
        name: 'Nandrolone Phenylpropionate',
        nickname: 'NPP',
        half_life_days: 1.5,
        dose_percentage_active: 0.67,
      },
      'Nandrolone-Decanoate': {
        name: 'Nandrolone Decanoate',
        nickname: 'Deca',
        half_life_days: 7.5,
        dose_percentage_active: 0.64,
      },
    },
    Equipoise: {
      has_esters: false,
      name: 'Equipoise',
      nickname: 'EQ',
      half_life_days: 14.0,
      dose_percentage_active: 0.61,
    },
    Primobolan: {
      has_esters: true,
      'Primobolan-Oral': {
        name: 'Primobolan Oral',
        nickname: 'Primo-Oral',
        half_life_days: 5,
        dose_percentage_active: 1,
      },
      'Primobolan-Injectable': {
        name: 'Primobolan Injectable',
        nickname: 'Primo-Depot',
        half_life_days: 4.5,
        dose_percentage_active: 0.7,
      },
    },
    Boldenone: {
      has_esters: true,
      'Boldenone-Acetate': {
        name: 'Boldenone Acetate',
        nickname: 'Bold-Ace',
        half_life_days: 5,
        dose_percentage_active: 1,
      },
      'Boldenone-Cypionate': {
        name: 'Boldenone Cypionate',
        nickname: 'Bold-Cyp',
        half_life_days: 5.0,
        dose_percentage_active: 0.69,
      },
    },
    Dihyrdoboldenone: {
      has_esters: true,
      'Dihyrdoboldenone-Cypionate': {
        name: 'Dihyrdoboldenone Cypionate',
        nickname: 'DHB-Cyp',
        half_life_days: 5.0,
        dose_percentage_active: 0.69,
      },
    },
    Halotestin: {
      has_esters: false,
      name: 'Halotestin',
      nickname: 'Halo',
      half_life_days: 7,
      dose_percentage_active: 1,
    },
    Anadrol: {
      has_esters: false,
      name: 'Anadrol',
      nickname: 'Anadrol',
      half_life_days: 14,
      dose_percentage_active: 1,
    },
    Dianabol: {
      has_esters: false,
      name: 'Dianabol',
      nickname: 'D-Bol',
      half_life_days: 5,
      dose_percentage_active: 1,
    },
    Turinabol: {
      has_esters: false,
      name: 'Turinabol',
      nickname: 'T-Bol',
      half_life_days: 16,
      dose_percentage_active: 1,
    },
    Winstrol: {
      has_esters: true,
      'Winstrol-Oral': {
        name: 'Winstrol Oral',
        nickname: 'Winny-Oral',
        half_life_days: 8,
        dose_percentage_active: 1,
      },
      'Winstrol-Injectable': {
        name: 'Winstrol Injectable',
        nickname: 'Winny-Depot',
        half_life_days: 1.0,
        dose_percentage_active: 0.87,
      },
    },
    Anavar: {
      has_esters: false,
      name: 'Anavar',
      nickname: 'Var',
      half_life_days: 10,
      dose_percentage_active: 1,
    },
    Superdrol: {
      has_esters: false,
      name: 'Superdrol',
      nickname: 'S-Drol',
      half_life_days: 10,
      dose_percentage_active: 1,
    },
  },
  Frequency: {
    'Single Dose': 0,
    'Twice a day': 0.5,
    'Every day': 1,
    'Every other day': 2,
    MWF: 69,
    'Every 3 days': 3,
    'Every 3.5 days': 3.5,
    'Every 4 days': 4,
    'Every 5 days': 5,
    'Every 6 days': 6,
    'Every 7 days': 7,
    'Every 10 days': 10,
    'Once every 2 weeks': 14,
    'Once a month': 30,
  },
};

export const DRUGS = {
  'Testosterone-Propionate': {
    name: 'Testosterone Propionate',
    nickname: 'Test-Prop',
    half_life_days: 0.8,
    dose_percentage_active: 0.8,
  },
  'Testosterone-Phenylpropionate': {
    name: 'Testosterone Phenylpropionate',
    nickname: 'Test-Phenyl',
    half_life_days: 1.5,
    dose_percentage_active: 0.66,
  },
  'Testosterone-Isocaproate': {
    name: 'Testosterone Isocaproate',
    nickname: 'Test-Iso',
    half_life_days: 4.0,
    dose_percentage_active: 0.72,
  },
  'Testosterone-Enanthate': {
    name: 'Testosterone Enanthate',
    nickname: 'Test-E',
    half_life_days: 4.5,
    dose_percentage_active: 0.7,
  },
  'Testosterone-Cypionate': {
    name: 'Testosterone Cypionate',
    nickname: 'Test-Cyp',
    half_life_days: 5.0,
    dose_percentage_active: 0.69,
  },
  'Testosterone-Decanoate': {
    name: 'Testosterone Decanoate',
    nickname: 'Test-D',
    half_life_days: 7.5,
    dose_percentage_active: 0.62,
  },
  'Testosterone-Undecanoate': {
    name: 'Testosterone Undecanoate',
    nickname: 'Test-U',
    half_life_days: 20.9,
    dose_percentage_active: 0.61,
  },
  'Trenbolone-Acetate': {
    name: 'Trenbolone Acetate',
    nickname: 'Tren-Ace',
    half_life_days: 1.0,
    dose_percentage_active: 0.87,
  },
  'Trenbolone-Enanthate': {
    name: 'Trenbolone Enanthate',
    nickname: 'Tren-E',
    half_life_days: 4.5,
    dose_percentage_active: 0.7,
  },
  'Masteron-Propionate': {
    name: 'Masteron Propionate',
    nickname: 'Mast-P',
    half_life_days: 0.8,
    dose_percentage_active: 0.8,
  },
  'Masteron-Enanthate': {
    name: 'Masteron Enanthate',
    nickname: 'Mast-E',
    half_life_days: 4.5,
    dose_percentage_active: 0.7,
  },
  'Nandrolone-Phenylpropionate': {
    name: 'Nandrolone Phenylpropionate',
    nickname: 'NPP',
    half_life_days: 1.5,
    dose_percentage_active: 0.67,
  },
  'Nandrolone-Decanoate': {
    name: 'Nandrolone Decanoate',
    nickname: 'Deca',
    half_life_days: 7.5,
    dose_percentage_active: 0.64,
  },
  Equipoise: {
    name: 'Equipoise',
    nickname: 'EQ',
    half_life_days: 14.0,
    dose_percentage_active: 0.61,
  },
  'Primobolan-Oral': {
    name: 'Primobolan Oral',
    nickname: 'Primo-Ace',
    half_life_days: 5 / 24,
    dose_percentage_active: 1,
  },
  'Primobolan-Injectable': {
    name: 'Primobolan Injectable',
    nickname: 'Primo-E',
    half_life_days: 4.5,
    dose_percentage_active: 0.7,
  },
  'Boldenone-Acetate': {
    name: 'Boldenone Acetate',
    nickname: 'Bold-Ace',
    half_life_days: 12 / 24,
    dose_percentage_active: 0.83,
  },
  'Boldenone-Cypionate': {
    name: 'Boldenone Cypionate',
    nickname: 'Bold-Cyp',
    half_life_days: 5.0,
    dose_percentage_active: 0.69,
  },
  'Dihyrdoboldenone-Cypionate': {
    name: 'Dihyrdoboldenone Cypionate',
    nickname: 'DHB-Cyp',
    half_life_days: 5.0,
    dose_percentage_active: 0.69,
  },
  Halotestin: {
    name: 'Halotestin',
    nickname: 'Halo',
    half_life_days: 7 / 24,
    dose_percentage_active: 1,
  },
  Anadrol: {
    name: 'Anadrol',
    nickname: 'Anadrol',
    half_life_days: 14 / 24,
    dose_percentage_active: 1,
  },
  Dianabol: {
    name: 'Dianabol',
    nickname: 'D-Bol',
    half_life_days: 5 / 24,
    dose_percentage_active: 1,
  },
  Turinabol: {
    name: 'Turinabol',
    nickname: 'T-Bol',
    half_life_days: 16 / 24,
    dose_percentage_active: 1,
  },
  'Winstrol-Oral': {
    name: 'Winstrol Oral',
    nickname: 'Winny-Oral',
    half_life_days: 8 / 24,
    dose_percentage_active: 1,
  },
  'Winstrol-Injectable': {
    name: 'Winstrol Injectable',
    nickname: 'Winny-Depot',
    half_life_days: 1.0,
    dose_percentage_active: 0.87,
  },
  Anavar: {
    name: 'Anavar',
    nickname: 'Var',
    half_life_days: 10 / 24,
    dose_percentage_active: 1,
  },
  Superdrol: {
    name: 'Superdrol',
    nickname: 'Superdrol',
    half_life_days: 10 / 24,
    dose_percentage_active: 1,
  },
};

export const CADENCES = {
  Frequency: {
    0: 'Single Dose',
    0.5: 'Twice a day',
    1: 'Every day',
    2: 'Every other day',
    69: 'MWF',
    3: 'Every 3 days',
    3.5: 'Every 3.5 days',
    4: 'Every 4 days',
    5: 'Every 5 days',
    6: 'Every 6 days',
    7: 'Every 7 days',
    10: 'Every 10 days',
    14: 'Once every 2 weeks',
    30: 'Once a month',
  },
};

export const DRUG_DOSES = [
  ('Equipoise', 900, 7, 1, 1),
  ('Equipoise', 900, 3.5, 1, 3),
  ('Equipoise', 600, 7, 4, 4),
  ('Testosterone-Cypionate', 250, 7, 1, 1),
  ('Testosterone-Cypionate', 300, 3.5, 1, 4.4),
  ('Testosterone-Cypionate', 300, 7, 4.7, 4.7),
  ('Testosterone-Cypionate', 200, 7, 5, 5),
  ('Testosterone-Cypionate', 300, 3.5, 5.5, 20),
];

export const FULL_NAMES = [
  'Testosterone-Cypionate',
  'Testosterone-Enanthate',
  'Testosterone-Propionate',
  'Testosterone-Suspension',
  'Testosterone-Phenylpropionate',
  'Testosterone-Undecanoate',
  'Trenbolone-Acetate',
  'Trenbolone-Enanthate',
  'Masteron-Propionate',
  'Masteron-Enanthate',
  'Nandrolone-Phenylpropionate',
  'Nandrolone-Decanoate',
  'Boldenone-Acetate',
  'Boldenone-Cypionate',
  'Dihydroboldenone-Cypionate',
  'Equipoise',
  'Primobolan-Oral',
  'Primobolan-Injectable',
  'Halotestin',
  'Anadrol',
  'Dianabol',
  'Turinabol',
  'Winstrol-Oral',
  'Winstrol-Injectable',
  'Anavar',
  'Superdrol',
];

export const BLOOD_UNITS = {
  E2: 'pg/mL',
  TEST: 'ng/dL',
  FREE_T: 'ng/dL',
  IGF1: 'ng/mL',
  FSH: 'mIU/mL',
  LH: 'mIU/mL',
  HEMOGLOBIN: 'g/dL',
  HEMATOCRIT: '%',
  WBC: '10^3/uL',
  RBC: '10^6/uL',
  AST: 'U/L',
  ALT: 'U/L',
  GGT: 'U/L',
  BILIRUBIN: 'mg/dL',
  PSA: 'ng/mL',
  GLUCOSE: 'mg/dL',
  INSULIN: 'pmol/L',
  ALBUMIN: 'g/dL',
  CREATININE: 'mg/dL',
  CHOLESTEROL: 'mg/dL',
  TRIGLYCERIDES: 'mg/dL',
  HDL: 'mg/dL',
  LDL: 'mg/dL',
  'LDL/HDL': 'ratio',
  SHBG: 'nmol/L',
  TSH: 'mIU/L',
};

export const ANCILLARIES = {
  Arimidex: {
    chemical: 'Anastrozole',
    nickname: 'Adex',
    units: 'mg',
  },
  Aromasin: {
    chemical: 'Exemestane',
    nickname: 'Asin',
    units: 'mg',
  },
  Clomiphene: {
    nickname: 'Clomid',
    units: 'mg',
  },
  Nolvadex: {
    chemical: 'Tamoxifen',
    nickname: 'Nolva',
    units: 'mg',
  },
  Raloxifene: {
    nickname: 'Ralox',
    units: 'mg',
  },
  Cabergoline: {
    nickname: 'Caber',
    units: 'mg',
  },
  Proviron: {
    chemical: 'Mesterolone',
    units: 'mg',
  },
  HCG: {
    units: 'IU',
    chemical: 'Human Chorionic Gonadotropin',
  },
  HMG: { units: 'IU', chemical: 'Human Menopausal Gonadotropin' },
  HGH: { units: 'IU', chemical: 'Human Growth Hormone' },
  T3: { units: 'mcg', chemical: 'Triiodothyronine' },
  T4: { units: 'mcg', chemical: 'Thyroxine' },
  Cialis: {
    chemical: 'Taladafil',
    units: 'mg',
  },
};

export const NO_AUTH_BLOODS = [
  {
    id: uuid_v4(),
    date: moment().add(4, 'days').format('YYYY-MM-DD HH:mm:ssZ'),
    // created_at: '2022-11-13T19:57:54.239813+00:00',
    // updated_at: null,
    cycle_id: 'c4446de0-6ab5-488b-b085-4dc8547f0e1d',
    user_id: '34364ed9-fca7-4f4b-bd47-64a1061d0a5d',
    physician: null,
    info: null,
    E2: 34,
    TEST: 1245,
    FREE_T: 300,
    IGF1: 250,
    FSH: null,
    LH: null,
    HEMOGLOBIN: null,
    HEMATOCRIT: null,
    WBC: null,
    RBC: null,
    AST: null,
    ALT: null,
    GGT: null,
    BILIRUBIN: null,
    PSA: null,
    GLUCOSE: null,
    INSULIN: null,
    ALBUMIN: null,
    CREATININE: null,
    CHOLESTEROL: null,
    TRIGLYCERIDES: null,
    HDL: null,
    LDL: null,
    'LDL/HDL': null,
    SHBG: null,
    TSH: null,
  },
  {
    id: uuid_v4(),
    date: moment().add(2, 'weeks').format('YYYY-MM-DD HH:mm:ssZ'),
    // created_at: '2022-11-13T19:58:21.943511+00:00',
    // updated_at: '2023-02-03T07:30:29.03+00:00',
    cycle_id: 'c4446de0-6ab5-488b-b085-4dc8547f0e1d',
    user_id: '34364ed9-fca7-4f4b-bd47-64a1061d0a5d',
    physician: null,
    info: null,
    E2: 40,
    TEST: 1700,
    FREE_T: 450,
    IGF1: 400,
    FSH: null,
    LH: null,
    HEMOGLOBIN: null,
    HEMATOCRIT: null,
    WBC: null,
    RBC: null,
    AST: null,
    ALT: null,
    GGT: null,
    BILIRUBIN: null,
    PSA: null,
    GLUCOSE: null,
    INSULIN: null,
    ALBUMIN: null,
    CREATININE: null,
    CHOLESTEROL: null,
    TRIGLYCERIDES: null,
    HDL: null,
    LDL: null,
    'LDL/HDL': null,
    SHBG: null,
    TSH: null,
  },
  {
    id: uuid_v4(),
    date: moment().add(5, 'weeks').format('YYYY-MM-DD HH:mm:ssZ'),
    // created_at: '2022-11-13T19:58:43.13962+00:00',
    // updated_at: '2023-02-03T07:30:22.333+00:00',
    cycle_id: 'c4446de0-6ab5-488b-b085-4dc8547f0e1d',
    user_id: '34364ed9-fca7-4f4b-bd47-64a1061d0a5d',
    physician: null,
    info: null,
    E2: 45,
    TEST: 2000,
    FREE_T: 450,
    IGF1: 350,
    FSH: null,
    LH: null,
    HEMOGLOBIN: null,
    HEMATOCRIT: null,
    WBC: null,
    RBC: null,
    AST: null,
    ALT: null,
    GGT: null,
    BILIRUBIN: null,
    PSA: null,
    GLUCOSE: null,
    INSULIN: null,
    ALBUMIN: null,
    CREATININE: null,
    CHOLESTEROL: null,
    TRIGLYCERIDES: null,
    HDL: null,
    LDL: null,
    'LDL/HDL': null,
    SHBG: null,
    TSH: null,
  },
  {
    id: uuid_v4(),
    date: moment().add(14, 'weeks').format('YYYY-MM-DD HH:mm:ssZ'),
    // created_at: '2022-11-13T19:58:43.13962+00:00',
    // updated_at: '2023-02-03T07:30:22.333+00:00',
    cycle_id: 'c4446de0-6ab5-488b-b085-4dc8547f0e1d',
    user_id: '34364ed9-fca7-4f4b-bd47-64a1061d0a5d',
    physician: null,
    info: null,
    E2: 30,
    TEST: 2500,
    FREE_T: 300,
    IGF1: 300,
    FSH: null,
    LH: null,
    HEMOGLOBIN: null,
    HEMATOCRIT: null,
    WBC: null,
    RBC: null,
    AST: null,
    ALT: null,
    GGT: null,
    BILIRUBIN: null,
    PSA: null,
    GLUCOSE: null,
    INSULIN: null,
    ALBUMIN: null,
    CREATININE: null,
    CHOLESTEROL: null,
    TRIGLYCERIDES: null,
    HDL: null,
    LDL: null,
    'LDL/HDL': null,
    SHBG: null,
    TSH: null,
  },
];

export const NO_AUTH_ANCILLARIES = [
  {
    id: uuid_v4(),
    name: 'Aromasin',
    frequency: 4,
    dose: 12.5,
    date: moment().format('YYYY-MM-DD HH:mm:ssZ'),
    start_wk: 1,
    stop_wk: 16,
  },
  {
    id: uuid_v4(),
    name: 'HGH',
    frequency: 3,
    dose: 6,
    date: moment().format('YYYY-MM-DD HH:mm:ssZ'),
    start_wk: 1,
    stop_wk: 16,
  },
  {
    id: uuid_v4(),
    name: 'HGH',
    frequency: 5,
    dose: 10,
    date: moment().format('YYYY-MM-DD HH:mm:ssZ'),
    start_wk: 1,
    stop_wk: 16,
  },
  {
    id: uuid_v4(),
    name: 'Cabergoline',
    frequency: 7,
    dose: 0.25,
    date: moment().format('YYYY-MM-DD HH:mm:ssZ'),
    start_wk: 4,
    stop_wk: 16,
  },
];
