import React from 'react';
import { lighterColorHash, variableLightColorHash } from 'helpers/colorHash';
import { useTheme, Chip, Box, Card, Stack } from '@mui/material';
import moment from 'moment';
import { BLOOD_UNITS } from '../../constants';

const BloodsTooltip = ({ tooltipData, cycle_start_date }) => {
  const theme = useTheme();
  const data = tooltipData.datumByKey;
  const nearest_date = tooltipData.nearestDatum.datum.date;
  const date = data[Object.keys(data)[0]]?.datum?.date || null;
  const days = moment(Date.parse(date))
    .startOf('day')
    .diff(moment(cycle_start_date).startOf('day'), 'days');

  const cards = [];

  // Filters to only blood markers at the nearest date
  Object.keys(data)
    .filter(
      (key) =>
        Object.keys(BLOOD_UNITS).includes(key) &&
        Date.parse(data[key].datum.date) === Date.parse(nearest_date)
    )
    .forEach((marker, i) => {
      cards.push(
        <Card key={marker + '_card_' + i}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              background: lighterColorHash(marker),
            }}>
            <Chip
              size='small'
              key={marker + '_chip_name'}
              label={`${marker}`}
              sx={{
                borderRadius: '0px',
                backgroundColor: 'transparent',
              }}
            />
            <Chip
              size='small'
              key={marker + '_chip_level'}
              label={`${data[marker].datum[marker]} ${BLOOD_UNITS[marker]}`}
              sx={{
                borderRadius: '0px',
                backgroundColor: 'transparent',
              }}
            />
          </Box>
        </Card>
      );
    });

  return cards.length ? (
    <div>
      <Stack spacing={0.5}>
        <Card
          sx={{
            m: 0,
            p: 0,
            backgroundColor: theme.palette.primary.main,
          }}
          key={'tooltip-card'}>
          <Chip
            size='small'
            label={`${moment(Date.parse(date)).format('MMM D')} - Day ${days}`}
            sx={{
              borderRadius: '0px',
              width: '100%',
              color: theme.palette.primary.contrastText,
            }}
          />
        </Card>
        {cards}
      </Stack>
    </div>
  ) : (
    <></>
  );
};

export default BloodsTooltip;
