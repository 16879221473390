import { STEP } from 'constants';
// computeOffset = (cycle_start_date, start_wk, frequency, date, step) =>
//    returns # of days from cycle start to first dose
import { computeOffset } from 'helpers/roid_calc_v2';
import moment from 'moment';

// max_levels is an object with the highest value for each ancillary by name
//    ex: { HGH: 10 }
//    This is used to scale the y-axis for each ancillary according to it's max value
// ancillary_graph_data is an object with an array of objects for each ancillary by name
//    each object in an array has a date and the value for ancillary
//    ex: { date: '2021-01-01', T: 1000 } for TSH
//    This keeps the data set for each ancillary separate so that we can
//    easily map over each ancillary and create a line for each one
export const ancillariesPrepper = (
  ancillaries,
  cycle_start_date,
  hidden_ancillaries = {}
) => {
  if (ancillaries?.length) {
    let max_levels = {};
    let graph_arrays_by_ancillary = {};
    ancillaries.forEach((ancillary, i) => {
      const { id, name, dose, date, frequency } = ancillary;

      // create the graph data for each ancillary
      if (!(name in hidden_ancillaries)) {
        if (!frequency) {
          if (graph_arrays_by_ancillary[name]) {
            graph_arrays_by_ancillary[name].push({
              date: moment(date).startOf('day').format(),
              [name]: dose,
            });
          } else {
            graph_arrays_by_ancillary[name] = [
              { date: moment(date).startOf('day').format(), [name]: dose },
            ];
          }
        } else {
          graph_arrays_by_ancillary[name + '_' + id] = createGraphData(
            ancillary,
            cycle_start_date
          );
        }
      }

      // reset the max for the ancillary if necessary
      if (name in max_levels) {
        if (dose > max_levels[name]) {
          max_levels[name] = dose;
        }
      } else {
        max_levels[name] = dose;
      }
    });
    return [max_levels, graph_arrays_by_ancillary];
  }
  return [{}, {}];
};

// return an array of objects with the date and dose for each step in repeat dose
const createGraphData = (ancillary, cycle_start_date) => {
  const { id, name, dose, date, frequency, start_wk, stop_wk } = ancillary;

  let graph_data_array = [];
  const offset = computeOffset(
    cycle_start_date,
    start_wk,
    frequency,
    date,
    STEP
  );
  let doses = Math.round(((stop_wk - start_wk + 1) * 7) / frequency);

  for (
    let i = offset;
    i < (doses * frequency) / STEP + offset;
    i += frequency / STEP
  ) {
    graph_data_array.push({
      date: moment(cycle_start_date)
        .startOf('day')
        .add(i * STEP, 'days')
        .format(),
      days: (i * STEP).toFixed(1),
      [name]: dose,
    });
  }

  return graph_data_array;
};
