import { createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import { cyclesAPI } from './cyclesAPI';
import { authAPI } from '../auth/authAPI';
import { customToast } from '../../helpers/customToast';
import { v4 as uuid_v4 } from 'uuid';
import moment from 'moment';

const initialState = {
  all: [],
  active_cycle_id: null,
  creating: true,
  editing: false,
  notes_showing: false,
  no_auth_cycle: {
    id: uuid_v4(),
    title: 'Login to Save Your Cycle',
    start_date: moment().format('YYYY-MM-DD HH:mm:ssZ'),
    weeks: 16,
    notes:
      'Please Sign Up or Login to Save Your Cycle. After logging in, you can add Notes, Ancillaries, Blood Work, and Journal Entries.',
  },
};

const cyclesSlice = createSlice({
  name: 'cycles',
  initialState,
  reducers: {
    resetCycles: () => initialState,
    setActiveCycle: (state, action) => {
      state.active_cycle_id = action.payload;
      state.editing = false;
      state.creating = false;
    },
    updateCycle: (state, action) => {
      state.no_auth_cycle = action.payload;
      state.editing = false;
      window.dataLayer.push({
        event: 'no_auth_cycle_updated',
        category: 'CRUD',
        action: 'UPDATE',
        table: 'Cycles',
        title: action.payload.title,
        weeks: action.payload.weeks,
        start_date: action.payload.start_date,
        auth: false,
      });
    },
    initiateNewCycle: (state) => {
      state.active_cycle_id = null;
      state.creating = true;
      state.editing = true;
    },
    initiateNoAuthCycle: (state) => {
      state.active_cycle_id = state.no_auth_cycle.id;
      state.creating = false;
      state.editing = false;
    },
    toggleEditing: (state) => {
      state.editing = !state.editing;
    },
    setEditing: (state, { payload }) => {
      state.editing = payload;
    },
    toggleNotesShowing: (state) => {
      state.notes_showing = !state.notes_showing;
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(authAPI.endpoints.logout.matchFulfilled, () => {
        customToast('success', 'Logged out successfully');
        return initialState;
      })
      .addMatcher(
        cyclesAPI.endpoints.getCycleById.matchFulfilled,
        (state, { payload }) => {
          state.creating = false;
        }
      )
      .addMatcher(
        cyclesAPI.endpoints.createCycle.matchFulfilled,
        (state, { payload }) => {
          state.active_cycle_id = payload[0].id;
          state.creating = false;
          state.editing = false;
          customToast('success', 'Cycle created successfully');
          window.dataLayer.push({
            event: 'cycle_created',
            category: 'CRUD',
            action: 'CREATE',
            table: 'Cycles',
            title: payload[0].title,
            weeks: payload[0].weeks,
            start_date: payload[0].start_date,
            auth: true,
          });
        }
      )
      .addMatcher(
        cyclesAPI.endpoints.updateCycle.matchFulfilled,
        (state, { payload }) => {
          state.active_cycle_id = payload?.id;
          state.editing = false;
          customToast('success', 'Cycle updated successfully');
          window.dataLayer.push({
            event: 'cycle_updated',
            category: 'CRUD',
            action: 'UPDATE',
            table: 'Cycles',
            title: payload[0].title,
            weeks: payload[0].weeks,
            start_date: payload[0].start_date,
            auth: true,
          });
        }
      )
      .addMatcher(
        cyclesAPI.endpoints.deleteCycle.matchFulfilled,
        (state, { payload }) => {
          state.active_cycle_id = null;
          state.creating = true;
          state.editing = true;
          customToast('success', 'Cycle deleted successfully');
          window.dataLayer.push({
            event: 'cycle_deleted',
            category: 'CRUD',
            action: 'DELETE',
            table: 'Cycles',
            auth: true,
          });
        }
      );
  },
});

export const selectCycleInfo = createSelector(
  (state) => state.cycles.active_cycle_id,
  (state) => state.cycles.creating,
  (state) => state.cycles.editing,
  (state) => state.cycles.notes_showing,
  (active_cycle_id, creating, editing, notes_showing) => {
    return {
      active_cycle_id,
      creating,
      editing,
      notes_showing,
    };
  }
);

export const {
  resetCycles,
  initiateNewCycle,
  updateCycle,
  initiateNoAuthCycle,
  setActiveCycle,
  toggleEditing,
  setEditing,
  toggleNotesShowing,
} = cyclesSlice.actions;

export default cyclesSlice.reducer;
