// MUI
import {
  FormControl,
  Grid,
  TextField,
  InputAdornment,
  Dialog,
  DialogTitle,
  Box,
  Button,
} from '@mui/material';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider, DesktopDatePicker } from '@mui/x-date-pickers';
// React
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { useState, useEffect } from 'react';
// Features
import { openSignUp } from 'features/auth/authSlice';
import {
  useCreateBloodResultMutation,
  useUpdateBloodResultMutation,
} from 'features/bloods/bloodsAPI';
import { selectCycleInfo } from 'features/cycles/cyclesSlice';
// Custom Hooks
import { useGetBloodsByCycleId } from 'features/bloods/hooks';
// Helpers
import moment from 'moment';
import { customToast } from 'helpers/customToast';
import { BLOOD_UNITS } from '../../constants';

const initialStateValues = {
  id: '',
  date: moment(),
  ...Object.assign(
    {},
    ...Object.keys(BLOOD_UNITS).map((key) => ({
      [key]: '',
    }))
  ),
};

function BloodForm({ handleDialogClose, open, editing, current_blood_id }) {
  // Redux
  const dispatch = useDispatch();
  const user_id = useSelector((state) => state.auth.user_id);
  const is_authenticated = useSelector((state) => state.auth.is_authenticated);
  const { active_cycle_id } = useSelector(selectCycleInfo);
  // Custom Hooks
  const { data: bloods, isLoading, isFetching } = useGetBloodsByCycleId();

  const [createBloodResult] = useCreateBloodResultMutation();
  const [updateBloodResult] = useUpdateBloodResultMutation();

  const [allVals, setAllVals] = useState(initialStateValues);
  const [xs, sm] = [12, 4];
  const [og_blood, setOgBlood] = useState(null);

  useEffect(() => {
    if (editing && current_blood_id && bloods?.length) {
      const blood_result = bloods.find(
        (result) => result.id === current_blood_id
      );
      const filtered_blood_result = Object.assign(
        {},
        ...Object.keys(blood_result).map((key) => ({
          [key]: blood_result[key] || '',
        }))
      );
      setOgBlood(filtered_blood_result);
      setAllVals((state) => ({
        ...state,
        ...filtered_blood_result,
      }));
    } else {
      setAllVals(initialStateValues);
    }
  }, [editing, current_blood_id, bloods, open]);

  const handleBloodChange = (e) => {
    setAllVals({
      ...allVals,
      [e.target.name]:
        e.target.value >= 0
          ? e.target.value
          : e.target.value.replace(/\D/g, ''),
    });
  };

  const handleDateChange = (date) => {
    setAllVals({ ...allVals, date: date });
  };

  const resetState = () => {
    setAllVals(initialStateValues);
  };

  const cancelForm = () => {
    handleDialogClose();
    resetState();
  };

  const onSubmit = (e) => {
    e.preventDefault();

    if (!is_authenticated) {
      handleDialogClose();
      dispatch(openSignUp('create_update_bloods'));
      customToast('info', 'Please Sign Up or Login to add Blood Work', 5000);
      return;
    }

    // Validation
    if (!active_cycle_id) {
      customToast('error', 'No Cycle ID');
      return;
    } else if (!allVals.date) {
      customToast('error', 'No Date');
      return;
    } else if (!Object.keys(BLOOD_UNITS).some((key) => allVals[key])) {
      customToast('error', 'No Blood Result Data');
      return;
    }

    if (editing && allVals?.id && shallowEqual(allVals, og_blood)) {
      //compare newVals to og_drug
      //if they are the same, return
      handleDialogClose();
      customToast('info', 'No Changes');
      return;
    } else if (editing && allVals?.id) {
      updateBloodResult({
        ...allVals,
        cycle_id: active_cycle_id,
        id: allVals.id,
        updated_at: moment(),
      });
      cancelForm();
    } else {
      handleDialogClose();
      createBloodResult({ ...allVals, cycle_id: active_cycle_id, user_id });
    }
  };

  return (
    <Dialog open={open} onClose={handleDialogClose}>
      <form onSubmit={onSubmit} className='drug-form'>
        <DialogTitle sx={{ paddingBottom: 0 }}>
          {editing ? `Editing Blood Results` : 'Add New Blood Results'}
        </DialogTitle>
        <Grid container spacing={1} justifyContent='center' py={1} px={2}>
          <Grid item xs={xs} sm={sm}>
            <FormControl fullWidth>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DesktopDatePicker
                  required
                  name='date'
                  label='Date'
                  inputFormat='MM/DD/YYYY'
                  value={allVals.date}
                  onChange={(value) => handleDateChange(value)}
                  renderInput={(params) => (
                    <TextField variant='filled' {...params} />
                  )}
                />
              </LocalizationProvider>
            </FormControl>
          </Grid>

          {Object.entries(BLOOD_UNITS).map(([key, value]) => (
            <Grid item xs={xs} sm={sm} key={key + '_grid_form'}>
              <FormControl required fullWidth>
                <TextField
                  variant='filled'
                  label={key}
                  name={key}
                  value={allVals[key]}
                  onChange={handleBloodChange}
                  inputProps={{
                    min: 0,
                    max: 1000000,
                    inputMode: 'decimal',
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>{value}</InputAdornment>
                    ),
                  }}
                />
              </FormControl>
            </Grid>
          ))}
        </Grid>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', m: 1 }}>
          <Button variant='outlined' onClick={cancelForm}>
            {editing ? 'Cancel' : 'Cancel'}
          </Button>
          <Box sx={{ width: '5px' }}></Box>
          <Button variant='contained' type='submit'>
            {editing ? 'Update Blood Results' : 'Create Blood Results'}
          </Button>
        </Box>
      </form>
    </Dialog>
  );
}

export default BloodForm;
