import { api } from '../../app/api';
import { supabase } from '../../lib/api';

export const cyclesAPI = api.injectEndpoints({
  endpoints: (build) => ({
    getCycles: build.query({
      queryFn: async () => {
        const { data, error } = await supabase
          .from('Cycles')
          .select('*')
          .order('updated_at', { ascending: false });
        return error ? { error } : { data };
      },
      providesTags: ['Cycle'],
    }),
    createCycle: build.mutation({
      queryFn: async (cycle_data) => {
        const { data, error } = await supabase
          .from('Cycles')
          .insert([cycle_data])
          .select();
        return error ? { error } : { data };
      },
      invalidatesTags: ['Cycle'],
    }),
    getCycleById: build.query({
      queryFn: async (cycle_id) => {
        const { data, error } = await supabase
          .from('Cycles')
          .select(`*`)
          .eq('id', cycle_id)
          .limit(1)
          .single();
        return error ? { error } : { data };
      },
      providesTags: (result) => [{ type: 'Cycle', id: result.id }],
    }),
    updateCycle: build.mutation({
      queryFn: async (cycle_data) => {
        const { data, error } = await supabase
          .from('Cycles')
          .update(cycle_data)
          .eq('id', cycle_data.id)
          .select();
        return error ? { error } : { data };
      },
      invalidatesTags: (result) => [{ type: 'Cycle', id: result.id }, 'Cycle'],
    }),
    deleteCycle: build.mutation({
      queryFn: async (cycle_id) => {
        const { data, error } = await supabase
          .from('Cycles')
          .delete()
          .eq('id', cycle_id);
        return error ? { error } : { data };
      },
      invalidatesTags: ['Cycle'],
    }),
  }),
});

export const {
  useGetCyclesQuery,
  useCreateCycleMutation,
  useGetCycleByIdQuery,
  useUpdateCycleMutation,
  useDeleteCycleMutation,
} = cyclesAPI;
