// MUI
import {
  Grid,
  IconButton,
  Card,
  Typography,
  Box,
  Chip,
  Stack,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { Delete, Edit, MoreVert } from '@mui/icons-material';
// React
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NewItem } from 'components';
// Features
import { useDeleteAncillaryMutation } from 'features/ancillaries/ancillariesAPI';
// Custom Hooks
import { useGetAncillariesByCycleId } from 'features/ancillaries/hooks';
// Helpers
import { CADENCES, ANCILLARIES } from 'constants/index';
import moment from 'moment';
import { customToast } from 'helpers/customToast';
import { lighterColorHash } from 'helpers/colorHash';
import { openSignUp } from 'features/auth/authSlice';

function AncillariesList({ handleCreate, handleEdit }) {
  // Redux
  const dispatch = useDispatch();
  const is_authenticated = useSelector((state) => state.auth.is_authenticated);
  // Custom Hooks
  const { data: ancillaries, isLoading } = useGetAncillariesByCycleId();
  const [deleteDosage] = useDeleteAncillaryMutation();

  // Component State
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [activeAncillaryId, setActiveAncillaryId] = useState(null);
  const { xs, sm, md, xl } = { xs: 12, sm: 4, md: 3, xl: 2 };

  const handleOpenNavMenu = (e) => {
    setAnchorElNav(e.currentTarget);
    setActiveAncillaryId(e.currentTarget.id);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
    setActiveAncillaryId(null);
  };

  const handleDelete = (ancillary_id) => {
    !ancillary_id && customToast('error', 'No ancillary selected');
    setAnchorElNav(null);
    is_authenticated
      ? deleteDosage(ancillary_id)
      : dispatch(openSignUp('delete_ancillary')) &&
        customToast('info', 'Please Sign Up or Login to add and remove Ancillaries', 5000);
  };

  return (
    <Grid container direction='row' alignItems='stretch'>
      {ancillaries.map((ancillary) => (
        <Grid item key={ancillary.id} xs={xs} sm={sm} md={md} xl={xl} p={1}>
          <Card sx={{ width: '100%', height: '100%' }}>
            <Box
              sx={{
                position: 'relative',
                right: '0px',
                top: '0px',
                backgroundColor: lighterColorHash(ancillary.name),
                width: '100%',
                height: '6px',
              }}
            />
            <Stack direction={'row'} spacing={0} justifyContent='space-between'>
              <Box
                mt={0.5}
                ml={1.5}
                sx={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  justifyContent: 'flex-start',
                  alignItems: 'baseline',
                }}>
                <Typography variant='h6' component={'p'} p={0} mr={0.5}>
                  {ancillary.name}
                </Typography>
                {'chemical' in ANCILLARIES[ancillary.name] && (
                  <Typography variant='body1' component={'p'}>
                    <i>{ANCILLARIES[ancillary.name].chemical}</i>
                  </Typography>
                )}
              </Box>
              <Box sx={{ position: 'relative', right: '1px', top: '1px' }}>
                <IconButton
                  id={ancillary.id}
                  aria-label='settings'
                  onClick={handleOpenNavMenu}>
                  <MoreVert />
                </IconButton>
              </Box>
            </Stack>
            <Box sx={{ width: '95%' }} mx={1} mb={1}>
              <Chip
                sx={{ m: 0.5 }}
                label={`${ancillary.dose} ${ANCILLARIES[ancillary.name].units}`}
              />
              <Chip
                sx={{ m: 0.5 }}
                label={CADENCES.Frequency[ancillary.frequency]}
              />
              {ancillary.frequency !== 0 && (
                <Chip
                  sx={{ m: 0.5 }}
                  label={`Wks ${ancillary.start_wk}-${ancillary.stop_wk}`}
                />
              )}
              {ancillary.frequency === 0 && (
                <Chip
                  sx={{ m: 0.5 }}
                  label={`${moment(ancillary.date).format('MMM D, ddd')}`}
                />
              )}
            </Box>
          </Card>
        </Grid>
      ))}
      <Menu
        id='menu-appbar'
        anchorEl={anchorElNav}
        keepMounted
        open={Boolean(anchorElNav)}
        onClose={handleCloseNavMenu}>
        <MenuItem
          onClick={() => {
            setAnchorElNav(null);
            handleEdit(activeAncillaryId);
          }}>
          <ListItemIcon>
            <Edit fontSize='small' />
          </ListItemIcon>
          <ListItemText>Edit</ListItemText>
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleDelete(activeAncillaryId);
          }}>
          <ListItemIcon>
            <Delete fontSize='small' />
          </ListItemIcon>
          <ListItemText>Delete</ListItemText>
        </MenuItem>
      </Menu>
      <Grid
        item
        xs={xs}
        sm={ancillaries?.length ? sm : 12}
        md={ancillaries?.length ? md : 12}
        xl={ancillaries?.length ? xl : 12}
        p={ancillaries?.length ? 1 : 0}>
        <NewItem handleCreate={handleCreate} title={'Add a New Ancillary'} />
      </Grid>
    </Grid>
  );
}

export default AncillariesList;
