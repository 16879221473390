// React
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// MUI
import { VisibilityOff, Visibility } from '@mui/icons-material';
import {
  Grid,
  Button,
  Link,
  Box,
  FormControl,
  InputLabel,
  OutlinedInput,
  IconButton,
  InputAdornment,
  Divider,
  FormHelperText,
} from '@mui/material';
// Features
import { useLoginMutation, useSignUpMutation } from 'features/auth/authAPI';
// Helpers
import GoogleButton from 'react-google-button';
import { customToast } from 'helpers/customToast';
import { supabase } from 'lib/api';
// Redux
import { toggleSignUp } from 'features/auth/authSlice';

const { xs, sm, md } = { xs: 12, sm: 6, md: 4 };

const initial_form_data = {
  email: '',
  password: '',
  password_confirm: '',
};

const PopoverAuth = () => {
  // Redux
  const dispatch = useDispatch();
  const show_signup = useSelector((state) => state.auth.show_signup);
  // RTK Query
  const [login] = useLoginMutation();
  const [signUp] = useSignUpMutation();
  // Local State
  const [formData, setFormData] = useState(initial_form_data);
  const [signUpError, setSignUpError] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const { email, password, password_confirm } = formData;

  useEffect(() => {}, []);

  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    if (show_signup && password !== password_confirm) {
      setSignUpError('Passwords do not match');
      return;
    }

    show_signup ? signUp({ email, password }) : login({ email, password });
  };

  const handleOAuthLogin = async (provider) => {
    // You need to enable the third party auth you want in Authentication > Settings
    // Read more on: https://supabase.com/docs/guides/auth#third-party-logins
    let { error } = await supabase.auth.signInWithOAuth({ provider });
    if (error) {
      customToast('error', error.message);
    }
  };

  const forgotPassword = async (e) => {
    // Read more on https://supabase.com/docs/reference/javascript/reset-password-email#notes
    e.preventDefault();
    const email = prompt('Please enter your email:');

    if (email === null || email === '') {
      customToast('error', 'You must enter your email.');
    } else {
      let { error } = await supabase.auth.resetPasswordForEmail(email);
      if (error) {
        console.error('Error: ', error.message);
        customToast('error', error.message);
      } else {
        customToast('success', 'Password recovery email has been sent.');
      }
    }
  };

  const clearFormData = () => {
    setFormData(initial_form_data);
    setSignUpError('');
  };

  const switchLoginType = () => {
    dispatch(toggleSignUp());
    clearFormData();
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'center',
        height: '100%',
        width: '100%',
        p: { xs: 2 },
        alignItems: { xs: 'stretch', sm: 'center' },
      }}>
      <form className='login' onSubmit={onSubmit}>
        <Grid
          container
          px={0}
          rowSpacing={1.5}
          direction='column'
          alignItems='stretch'
          className='login'
          justifyContent={'center'}
          sx={{ width: '100%' }}>
          <Grid item xs={xs} sm={sm} md={md}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignSelf: 'center',
                marginBottom: { xs: 6, sm: 1 },
              }}>
              <Box
                component={'img'}
                sx={{
                  display: 'flex',
                  height: '100%',
                  width: '80%',
                  maxHeight: '100%',
                  alignSelf: 'center',
                  justifySelf: 'center',
                }}
                src='/just1cycle-white-text.svg'
                alt='title-logo'
              />
            </Box>
          </Grid>
          <Grid item xs={xs} sm={sm} md={md}>
            <FormControl
              variant='outlined'
              fullWidth
              required={true}
              hiddenLabel>
              <InputLabel>Email</InputLabel>
              <OutlinedInput
                name='email'
                type='text'
                value={email}
                onChange={onChange}
                label='Email'
              />
            </FormControl>
          </Grid>
          <Grid item xs={xs} sm={sm} md={md}>
            <FormControl
              variant='outlined'
              fullWidth
              required={true}
              error={!!signUpError}>
              <InputLabel htmlFor='outlined-adornment-password'>
                Password
              </InputLabel>
              <OutlinedInput
                name='password'
                type={showPassword ? 'text' : 'password'}
                value={password}
                onChange={onChange}
                inputProps={{
                  minLength: show_signup ? 8 : 5,
                  autoComplete: show_signup
                    ? 'new-password'
                    : 'current-password',
                }}
                endAdornment={
                  <InputAdornment position='end'>
                    <IconButton
                      aria-label='toggle password visibility'
                      onClick={() => setShowPassword(!showPassword)}
                      onMouseDown={(e) => e.preventDefault()}
                      edge='end'>
                      {showPassword ? (
                        <VisibilityOff fontSize='small' />
                      ) : (
                        <Visibility fontSize='small' />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
                label='Password'
              />
            </FormControl>
          </Grid>
          {show_signup && (
            <Grid item xs={xs} sm={sm} md={md}>
              <FormControl
                variant='outlined'
                fullWidth
                required={show_signup}
                error={!!signUpError && show_signup}>
                <InputLabel htmlFor='outlined-adornment-password'>
                  Confirm Password
                </InputLabel>
                <OutlinedInput
                  name='password_confirm'
                  type={showPassword ? 'text' : 'password'}
                  value={password_confirm}
                  onChange={onChange}
                  endAdornment={
                    <InputAdornment position='end'>
                      <IconButton
                        aria-label='toggle password visibility'
                        onClick={() => setShowPassword(!showPassword)}
                        onMouseDown={(e) => e.preventDefault()}
                        edge='end'>
                        {showPassword ? (
                          <VisibilityOff fontSize='small' />
                        ) : (
                          <Visibility fontSize='small' />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                  label='Confirm Password'
                />
                {!!signUpError && (
                  <FormHelperText id='my-helper-text'>
                    Passwords must match
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
          )}
          <Grid item xs={xs} sm={sm} md={md}>
            <Button fullWidth variant='contained' type='submit'>
              {show_signup ? 'Sign Up' : 'Login'}
            </Button>
          </Grid>
          <Grid item xs={xs} sm={sm} md={md}>
            <Box
              sx={{
                display: 'flex',
                flex: 1,
                justifyContent: 'space-between',
                alignItems: 'center',
              }}>
              <Link
                href='#'
                underline='hover'
                color={'secondary'}
                onClick={forgotPassword}>
                Forgot Password?
              </Link>
              {/* <Link
                href='#'
                underline='hover'
                color={'secondary'}
                onClick={() =>
                  setPrivacyPolicyVisible(!privacy_policy_visible)
                }>
                Privacy Policy
              </Link> */}
              <Link
                href='#'
                underline='hover'
                color={'secondary'}
                onClick={() => switchLoginType()}>
                {!show_signup ? 'Sign Up?' : 'Login?'}
              </Link>
            </Box>
          </Grid>
          <Grid item xs={xs} sm={sm} md={md}>
            <Box mb={2}>
              <Divider variant='middle' />
            </Box>
          </Grid>
        </Grid>

        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <GoogleButton
            type='dark'
            onClick={() => handleOAuthLogin('google')}
          />
        </Box>
      </form>
    </Box>
  );
};

export default PopoverAuth;
