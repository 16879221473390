// MUI
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  TextField,
  InputAdornment,
  Dialog,
  DialogTitle,
  Box,
  Button,
} from '@mui/material';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider, DesktopDatePicker } from '@mui/x-date-pickers';
// React
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { useState, useEffect } from 'react';
// Redux
import { openSignUp } from 'features/auth/authSlice';
// Custom Hooks
import {
  useGetDosesByCycleId,
  useCreateDosage,
  useUpdateDosage,
} from 'features/doses/hooks';
import { useGetCycleById } from 'features/cycles/hooks';
// Helpers
import { DRUG_FACTS } from 'constants';
import moment from 'moment';
import { customToast } from 'helpers/customToast';

const initialStateValues = {
  id: '',
  name: '',
  ester: '',
  frequency: '',
  dose: 0,
  date: moment(),
  start_wk: '',
  stop_wk: '',
};

function DrugCardForm({ handleDialogClose, open, editing, current_drug_id }) {
  // React
  const dispatch = useDispatch();
  // RTK Query
  const user_id = useSelector((state) => state.auth.user_id);
  const is_authenticated = useSelector((state) => state.auth.is_authenticated);
  // Custom Hooks
  const { data: cycle, isLoading: cycle_loading } = useGetCycleById();
  const { data: drugs, isLoading: doses_loading } = useGetDosesByCycleId();
  const {
    id: cycle_id,
    weeks: cycle_weeks,
    start_date: cycle_start_date,
  } = cycle || {};
  const [createDosage] = useCreateDosage();
  const [updateDosage] = useUpdateDosage();
  // Local State
  const [allVals, setAllVals] = useState(initialStateValues);
  const weeks = [...Array(cycle_weeks).keys()].map((i) => i + 1);
  const [xs, sm] = [12, 4];
  const { id, name, ester, frequency, dose, date, start_wk, stop_wk } = allVals;
  const [og_drug, setOgDrug] = useState(null);

  useEffect(() => {
    if (editing && current_drug_id) {
      const drug = drugs.find((drug) => drug.id === current_drug_id);
      const { createdAt, updatedAt, __v, ...drug_filtered } = drug;
      setOgDrug(drug_filtered);
      setAllVals((state) => ({
        ...state,
        ...drug_filtered,
      }));
    } else {
      setAllVals(initialStateValues);
    }
  }, [editing, current_drug_id, drugs, open]);

  const handleChange = (e) => {
    setAllVals({ ...allVals, [e.target.name]: e.target.value });
  };

  const handleCompoundChange = (e) => {
    setAllVals({ ...allVals, [e.target.name]: e.target.value, ester: '' });
  };

  const handleStartWkChange = (e) => {
    if (e.target?.value && stop_wk && e.target.value > stop_wk) {
      setAllVals({ ...allVals, [e.target.name]: e.target.value, stop_wk: '' });
    } else {
      setAllVals({ ...allVals, [e.target.name]: e.target.value });
    }
  };

  const handleDosageChange = (e) => {
    setAllVals({
      ...allVals,
      [e.target.name]:
        e.target.value >= 0
          ? e.target.value
          : e.target.value.replace(/\D/g, ''),
    });
  };

  const handleDateChange = (date) => {
    setAllVals({ ...allVals, date: date });
  };

  const resetState = () => {
    setAllVals(initialStateValues);
  };

  const cancelForm = () => {
    handleDialogClose();
    resetState();
  };

  const missing_ester =
    name && DRUG_FACTS.Compounds[name].has_esters && ester === ''
      ? true
      : false;

  const onSubmit = (e) => {
    e.preventDefault();

    // Sign Up Conversion if there are already 4 doses
    if (!editing && !is_authenticated && drugs.length >= 4) {
      handleDialogClose();
      dispatch(openSignUp('create_more_than_4_compounds'));
      customToast(
        'info',
        'Please Sign Up or Login to add more than 4 Compounds',
        5000
      );
      return;
    }

    // Validation
    if (!cycle_id) {
      customToast('error', 'No Cycle ID');
      return;
    } else if (frequency === 0 && !date) {
      customToast('error', 'No Date');
      return;
    } else if (frequency !== 0 && (start_wk === '' || stop_wk === '')) {
      customToast('error', 'Missing Weeks');
      return;
    } else if (frequency !== 0 && stop_wk < start_wk) {
      customToast('error', 'Stop Week is less than Start Week');
      return;
    }

    let newVals;

    // Set Weeks / Date dependently
    if (frequency === 0) {
      newVals = { ...allVals, start_wk: 1, stop_wk: 1 };
    } else {
      newVals = { ...allVals, date: cycle_start_date };
    }

    if (editing && id && shallowEqual(newVals, og_drug)) {
      //compare newVals to og_drug
      //if they are the same, return
      handleDialogClose();
      customToast('info', 'No Changes');
      return;
    } else if (editing && id) {
      updateDosage({ ...newVals, cycle_id, id, updated_at: moment() });
      cancelForm();
    } else {
      handleDialogClose();
      createDosage({ ...newVals, cycle_id, user_id });
    }
  };

  return (
    <Dialog open={open} onClose={handleDialogClose}>
      <form onSubmit={onSubmit} className='drug-form'>
        <DialogTitle sx={{ paddingBottom: 0 }}>
          {editing ? `Editing Compound` : 'Add New Compound'}
        </DialogTitle>
        <Grid container spacing={1} justifyContent='center' py={1} px={2}>
          <Grid item xs={xs} sm={sm}>
            <FormControl variant='filled' fullWidth>
              <InputLabel>Compound</InputLabel>
              <Select
                required
                name='name'
                value={name}
                label='Compound'
                onChange={handleCompoundChange}>
                {Object.entries(DRUG_FACTS.Compounds).map(([key, value]) => (
                  <MenuItem key={key} value={key}>
                    {key}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={xs} sm={sm}>
            <FormControl variant='filled' fullWidth>
              <InputLabel>Ester</InputLabel>
              <Select
                required={missing_ester}
                name='ester'
                value={ester}
                label='Ester'
                onChange={handleChange}
                disabled={!name || !DRUG_FACTS.Compounds[name].has_esters}>
                {name && DRUG_FACTS.Compounds[name].has_esters ? (
                  Object.keys(DRUG_FACTS.Compounds[name]).map(
                    (compound_full_name, i) => (
                      <MenuItem
                        key={i}
                        value={compound_full_name.split('-')[1]}>
                        {compound_full_name.split('-')[1]}
                      </MenuItem>
                    )
                  )
                ) : (
                  <MenuItem value={ester}>{ester}</MenuItem>
                )}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={xs} sm={sm}>
            <FormControl required fullWidth>
              <TextField
                variant='filled'
                label='Dosage'
                name='dose'
                placeholder='Dosage'
                value={dose}
                onChange={handleDosageChange}
                inputProps={{
                  min: 0,
                  max: 100000,
                  onInput: (value) => 69,
                  inputMode: 'numeric',
                  pattern: '[0-9]*',
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>mg</InputAdornment>
                  ),
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={xs} sm={sm}>
            <FormControl variant='filled' fullWidth>
              <InputLabel>Frequency</InputLabel>
              <Select
                required
                name='frequency'
                value={frequency}
                label='Frequency'
                onChange={handleChange}>
                {Object.entries(DRUG_FACTS.Frequency).map(([key, value]) => (
                  <MenuItem key={key} value={value}>
                    {key}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          {frequency === 0 ? (
            <Grid item xs={xs} sm={sm * 2}>
              <FormControl fullWidth>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DesktopDatePicker
                    required={frequency && frequency === 0 ? true : false}
                    name='date'
                    label='Date'
                    inputFormat='MM/DD/YYYY'
                    value={date}
                    onChange={(value) => handleDateChange(value)}
                    renderInput={(params) => (
                      <TextField variant='filled' {...params} />
                    )}
                  />
                </LocalizationProvider>
              </FormControl>
            </Grid>
          ) : (
            <Grid item xs={xs / 2} sm={sm}>
              <FormControl variant='filled' fullWidth>
                <InputLabel>Start Week</InputLabel>
                <Select
                  required={frequency && frequency !== 0 ? true : false}
                  name='start_wk'
                  value={start_wk}
                  label='start_wk'
                  onChange={handleStartWkChange}>
                  {weeks.map((i) => (
                    <MenuItem key={i} value={i}>
                      {i}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          )}
          {frequency === 0 ? (
            <></>
          ) : (
            <Grid item xs={xs / 2} sm={sm}>
              <FormControl variant='filled' fullWidth>
                <InputLabel>Stop Week</InputLabel>
                <Select
                  required={frequency && frequency !== 0 ? true : false}
                  name='stop_wk'
                  value={stop_wk}
                  label='stop_wk'
                  onChange={handleChange}>
                  {weeks.map((i) => {
                    if (!start_wk || i >= start_wk) {
                      return (
                        <MenuItem key={i} value={i}>
                          {i}
                        </MenuItem>
                      );
                    } else {
                      return null;
                    }
                  })}
                </Select>
              </FormControl>
            </Grid>
          )}
        </Grid>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', m: 1 }}>
          <Button variant='outlined' onClick={cancelForm}>
            {editing ? 'Cancel' : 'Cancel'}
          </Button>
          <Box sx={{ width: '5px' }}></Box>
          <Button variant='contained' type='submit'>
            {editing ? 'Update Compound' : 'Create Compound'}
          </Button>
        </Box>
      </form>
    </Dialog>
  );
}

export default DrugCardForm;
