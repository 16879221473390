import { api } from 'app/api';
import { supabase } from 'lib/api';
import moment from 'moment';
import { BLOOD_UNITS } from 'constants';

export const journalAPI = api.injectEndpoints({
  endpoints: (build) => ({
    getJournals: build.query({
      queryFn: async () => {
        const { data, error } = await supabase
          .from('Journals')
          .select('*')
          .order('updated_at', { ascending: false });
        return error ? { error } : { data };
      },
      providesTags: ['Journal'],
    }),
    getJournalsByCycleId: build.query({
      queryFn: async (cycle_id) => {
        const { data, error } = await supabase
          .from('Journals')
          .select(`*`)
          .eq('cycle_id', cycle_id)
          .order('date');
        return error ? { error } : { data };
      },
      providesTags: ['Journal'],
    }),
    createJournalEntry: build.mutation({
      queryFn: async (blood_result_data_with_id) => {
        const { id, ...blood_result_data } = blood_result_data_with_id;
        Object.keys(BLOOD_UNITS).forEach((key) => {
          if (blood_result_data[key] === '') {
            delete blood_result_data[key];
          }
        });
        const { data, error } = await supabase
          .from('Journals')
          .insert([blood_result_data])
          .select();
        return error || data.length ? { error } : { data };
      },
      invalidatesTags: ['Journal'],
    }),
    updateJournalEntry: build.mutation({
      queryFn: async (blood_data) => {
        Object.keys(blood_data).forEach((key) => {
          if (blood_data[key] === '') {
            delete blood_data[key];
          }
        });
        const { data, error } = await supabase
          .from('Journals')
          .update({ ...blood_data, updated_at: moment() })
          .eq('id', blood_data.id)
          .select();
        return error ? { error } : { data: data[0] };
      },
      invalidatesTags: ['Journal'],
    }),
    deleteJournalEntry: build.mutation({
      queryFn: async (id) => {
        const { data, error } = await supabase
          .from('Journals')
          .delete()
          .eq('id', id)
          .select();
        return error ? { error } : { data };
      },
      invalidatesTags: ['Journal'],
    }),
  }),
});

export const {
  useGetJournalsQuery,
  useGetJournalsByCycleIdQuery,
  useCreateJournalEntryMutation,
  useUpdateJournalEntryMutation,
  useDeleteJournalEntryMutation,
} = journalAPI;
