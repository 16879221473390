import { createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import { customToast } from '../../helpers/customToast';

const initialState = {
  menu_selected: 'COMPOUNDS',
  menu_items: ['COMPOUNDS', 'ANCILLARIES', 'BLOOD WORK', 'JOURNAL'],
  privacy_policy_visible: false,
};

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    setCycleMenu: (state, { payload }) => {
      state.menu_selected = payload;
    },
    setPrivacyPolicyVisible: (state, { payload }) => {
      state.privacy_policy_visible = payload;
    },
  },
});

export const selectDashboardInfo = createSelector(
  (state) => state.dashboard.menu_selected,
  (state) => state.dashboard.menu_items,
  (menu_selected, menu_items) => {
    return {
      menu_selected,
      menu_items,
    };
  }
);

export const { setCycleMenu, setPrivacyPolicyVisible } = dashboardSlice.actions;

export default dashboardSlice.reducer;
