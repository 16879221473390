import { api } from '../../app/api';
import { supabase } from '../../lib/api';
import moment from 'moment';

export const dosesAPI = api.injectEndpoints({
  endpoints: (build) => ({
    getDoses: build.query({
      queryFn: async () => {
        const { data, error } = await supabase
          .from('Doses')
          .select('*')
          .order('updated_at', { ascending: false });
        return error ? { error } : { data };
      },
      providesTags: ['Dose'],
    }),
    getDosesByCycleId: build.query({
      queryFn: async (cycle_id) => {
        const { data, error } = await supabase
          .from('Doses')
          .select(`*`)
          .eq('cycle_id', cycle_id)
          .order('created_at');
        return error ? { error } : { data };
      },
      providesTags: ['Dose'],
    }),
    createDosage: build.mutation({
      queryFn: async (doseDataWithId) => {
        const { id, ...doseData } = doseDataWithId;
        const { data, error } = await supabase
          .from('Doses')
          .insert([doseData])
          .select();
        return error || data.length ? { error } : { data };
      },
      invalidatesTags: ['Dose'],
    }),
    updateDosage: build.mutation({
      queryFn: async (drug_data) => {
        const { data, error } = await supabase
          .from('Doses')
          .update({ ...drug_data, updated_at: moment() })
          .eq('id', drug_data.id)
          .select();
        return error ? { error } : { data: data[0] };
      },
      invalidatesTags: ['Dose'],
    }),
    deleteDosage: build.mutation({
      queryFn: async (id) => {
        const { data, error } = await supabase
          .from('Doses')
          .delete()
          .eq('id', id)
          .select();
        return error ? { error } : { data };
      },
      invalidatesTags: ['Dose'],
    }),
  }),
});

export const {
  useGetDosesQuery,
  useGetDosesByCycleIdQuery,
  useCreateDosageMutation,
  useUpdateDosageMutation,
  useDeleteDosageMutation,
} = dosesAPI;
