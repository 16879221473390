import { createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import { customToast } from '../../helpers/customToast';
import { ancillariesAPI } from './ancillariesAPI';
import { v4 as uuid_v4 } from 'uuid';
import moment from 'moment';
import { NO_AUTH_ANCILLARIES } from 'constants';

const initialState = {
  status: 'idle',
  message: '',
  no_auth_ancillaries: NO_AUTH_ANCILLARIES,
};

export const ancillariesSlice = createSlice({
  name: 'ancillaries',
  initialState,
  reducers: {
    reset: (state) => initialState,
    createAncillary: (state, action) => {
      state.no_auth_ancillaries.push(action.payload);
    },
    updateAncillary: (state, action) => {
      const index = state.no_auth_ancillaries.findIndex(
        (dose) => dose.id === action.payload.id
      );
      state.no_auth_ancillaries[index] = action.payload;
    },
    deleteAncillary: (state, action) => {
      state.no_auth_ancillaries = state.no_auth_ancillaries.filter(
        (dose) => dose.id !== action.payload
      );
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        ancillariesAPI.endpoints.createAncillary.matchFulfilled,
        () => {
          customToast('success', 'Ancillary created successfully');
          window.dataLayer.push({
            event: 'ancillary_created',
            category: 'CRUD',
            table: 'Ancillaries',
            action: 'CREATE',
            auth: true,
          });
        }
      )
      .addMatcher(
        ancillariesAPI.endpoints.updateAncillary.matchFulfilled,
        () => {
          customToast('success', 'Ancillary updated successfully');
          window.dataLayer.push({
            event: 'ancillary_updated',
            category: 'CRUD',
            table: 'Ancillaries',
            action: 'UPDATE',
            auth: true,
          });
        }
      )
      .addMatcher(
        ancillariesAPI.endpoints.deleteAncillary.matchFulfilled,
        () => {
          customToast('success', 'Ancillary deleted successfully');
          window.dataLayer.push({
            event: 'ancillary_deleted',
            category: 'CRUD',
            table: 'Ancillaries',
            action: 'DELETE',
            auth: true,
          });
        }
      );
  },
});

export const { reset, createAncillary, updateAncillary, deleteAncillary } =
  ancillariesSlice.actions;
export default ancillariesSlice.reducer;
