import TagManager from 'react-gtm-module';

const initializeGoogleTagManager = () => {
  const tagManagerArgs = {
    gtmId: process.env.REACT_APP_GTM_ID,
  };

  TagManager.initialize(tagManagerArgs);
};

export default initializeGoogleTagManager;
