import React from 'react';
import { lighterColorHash } from 'helpers/colorHash';
import { LineSeries, GlyphSeries } from '@visx/xychart';
import moment from 'moment';
import { useGetBloodsByCycleId } from 'features/bloods/hooks';

const BloodLines = ({
  cycle_start_date,
  weeks,
  max_release,
  max_blood_levels = {},
  bloods_by_marker = {},
}) => {
  const { data: bloods } = useGetBloodsByCycleId();

  const getDaysFromStart = (blood_date) => {
    const days_in_cycle = moment(cycle_start_date)
      .add(weeks * 7, 'days')
      .diff(moment(cycle_start_date), 'days');
    const days_diff = moment(blood_date)
      .startOf('day')
      .diff(moment(cycle_start_date).startOf('day'), 'days');
    if (days_diff < 0) {
      return 0;
    } else if (days_diff > days_in_cycle) {
      return days_in_cycle;
    } else {
      return days_diff;
    }
  };

  const getBloodLevel = (blood, marker) => {
    return blood[marker]
      ? ((max_release || max_blood_levels[marker]) / max_blood_levels[marker]) *
          blood[marker]
      : 'null';
  };

  if (bloods?.length === 0) return null;

  return (
    <>
      {Object.keys(bloods_by_marker).map((marker) => (
        <React.Fragment key={marker + '_blood_bars_fragment'}>
          <LineSeries
            key={marker + '_line'}
            dataKey={marker}
            data={bloods_by_marker[marker]}
            xAccessor={(blood) => getDaysFromStart(blood.date).toFixed(1)}
            yAccessor={(blood) => getBloodLevel(blood, marker)}
            colorAccessor={(blood) => lighterColorHash(marker)}
            strokeWidth={2}
          />
          <GlyphSeries
            key={marker + '_glyph'}
            dataKey={marker}
            data={bloods_by_marker[marker]}
            xAccessor={(blood) => getDaysFromStart(blood.date).toFixed(1)}
            yAccessor={(blood) => getBloodLevel(blood, marker)}
            colorAccessor={(blood) => lighterColorHash(marker)}
            strokeWidth={2}
          />
        </React.Fragment>
      ))}
    </>
  );
};

export default BloodLines;
