import { api } from 'app/api';
import { supabase } from 'lib/api';
import moment from 'moment';

export const ancillariesAPI = api.injectEndpoints({
  endpoints: (build) => ({
    getAncillaries: build.query({
      queryFn: async () => {
        const { data, error } = await supabase
          .from('Ancillaries')
          .select('*')
          .order('updated_at', { ascending: false });
        return error ? { error } : { data };
      },
      providesTags: ['Ancillary'],
    }),
    getAncillariesByCycleId: build.query({
      queryFn: async (cycle_id) => {
        const { data, error } = await supabase
          .from('Ancillaries')
          .select(`*`)
          .eq('cycle_id', cycle_id)
          .order('date');
        return error ? { error } : { data };
      },
      providesTags: ['Ancillary'],
    }),
    createAncillary: build.mutation({
      queryFn: async (ancillary_data_with_id) => {
        const { id, ...ancillary_data } = ancillary_data_with_id;

        const { data, error } = await supabase
          .from('Ancillaries')
          .insert([ancillary_data])
          .select();
        return error || data.length ? { error } : { data };
      },
      invalidatesTags: ['Ancillary'],
    }),
    updateAncillary: build.mutation({
      queryFn: async (ancillary_data) => {
        const { data, error } = await supabase
          .from('Ancillaries')
          .update({ ...ancillary_data, updated_at: moment() })
          .eq('id', ancillary_data.id)
          .select();
        return error ? { error } : { data: data[0] };
      },
      invalidatesTags: ['Ancillary'],
    }),
    deleteAncillary: build.mutation({
      queryFn: async (id) => {
        const { data, error } = await supabase
          .from('Ancillaries')
          .delete()
          .eq('id', id)
          .select();
        return error ? { error } : { data };
      },
      invalidatesTags: ['Ancillary'],
    }),
  }),
});

export const {
  useGetAncillariesQuery,
  useGetAncillariesByCycleIdQuery,
  useCreateAncillaryMutation,
  useUpdateAncillaryMutation,
  useDeleteAncillaryMutation,
} = ancillariesAPI;
