// MUI
import { IconButton, Card, Tooltip } from '@mui/material';
import { Add } from '@mui/icons-material';

const NewItem = ({ handleCreate, title, has_items = false }) => {
  return (
    <Card sx={{ width: '100%', height: '100%' }}>
      <Tooltip title={title}>
        <IconButton
          edge='end'
          aria-label='edit'
          onClick={handleCreate}
          sx={{ height: 1, width: 1, borderRadius: 0 }}>
          <Add sx={{ fontSize: has_items ? 30 : 60 }} />
        </IconButton>
      </Tooltip>
    </Card>
  );
};

export default NewItem;
