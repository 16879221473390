// React
import { useSelector } from 'react-redux';
// RTK Query API
import { useGetJournalsByCycleIdQuery } from './journalAPI';
// Redux
import { selectCycleInfo } from 'features/cycles/cyclesSlice';

// Custom Hook wrapper around useGetJournalsByCycleIdQuery
export const useGetJournalsByCycleId = () => {
  const { active_cycle_id } = useSelector(selectCycleInfo);
  const is_authenticated = useSelector((state) => state.auth.is_authenticated);

  const {
    data: journals,
    isSuccess,
    isLoading,
    isFetching,
  } = useGetJournalsByCycleIdQuery(active_cycle_id, {
    skip: !is_authenticated || !active_cycle_id,
  });

  return {
    data: is_authenticated ? journals : [],
    isSuccess: is_authenticated ? isSuccess : true,
    isLoading,
    isFetching,
  };
};
