// MUI
import { TextField, Box, IconButton } from '@mui/material';
import { Check, Close } from '@mui/icons-material';
import {
  TimelineItem,
  TimelineSeparator,
  TimelineContent,
  TimelineConnector,
  TimelineOppositeContent,
  TimelineDot,
} from '@mui/lab';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider, DesktopDatePicker } from '@mui/x-date-pickers';
// React
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { useState } from 'react';
// Features
import { openSignUp } from 'features/auth/authSlice';
import { selectCycleInfo } from 'features/cycles/cyclesSlice';
import {
  useCreateJournalEntryMutation,
  useUpdateJournalEntryMutation,
} from 'features/journal/journalAPI';
// Helpers
import moment from 'moment';
import { customToast } from 'helpers/customToast';

const JournalForm = ({
  editing = false,
  journal = { date: moment(), text: '' },
  cancelForm,
}) => {
  // Redux
  const dispatch = useDispatch();
  const user_id = useSelector((state) => state.auth.user_id);
  const is_authenticated = useSelector((state) => state.auth.is_authenticated);
  const { active_cycle_id } = useSelector(selectCycleInfo);
  // RTK Query
  const [createJournalEntry] = useCreateJournalEntryMutation();
  const [updateJournalEntry] = useUpdateJournalEntryMutation();
  // Local State
  const [form_data, setFormData] = useState(journal);

  const handleFormDataChange = (e) => {
    setFormData({
      ...form_data,
      [e.target.name]: e.target.value,
    });
  };

  const handleDateChange = (date) => {
    setFormData({
      ...form_data,
      date,
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();

    if (!is_authenticated) {
      cancelForm();
      dispatch(openSignUp('create_update_journal'));
      customToast(
        'info',
        'Please Sign Up or Login to add and remove Journal Entries',
        5000
      );
      return;
    }

    // Validation
    if (!active_cycle_id) {
      customToast('error', 'No Cycle ID');
      return;
    } else if (!form_data.date) {
      customToast('error', 'No Date');
      return;
    } else if (!form_data.text) {
      customToast('error', 'No Text');
      return;
    }

    if (editing && form_data?.id && shallowEqual(form_data, journal)) {
      //compare newVals to og_drug
      //if they are the same, return
      cancelForm();
      customToast('info', 'No Changes');
      return;
    } else if (editing && form_data?.id) {
      updateJournalEntry({
        ...form_data,
        cycle_id: active_cycle_id,
        id: journal.id,
        updated_at: moment(),
      });
      cancelForm();
    } else {
      createJournalEntry({ ...form_data, cycle_id: active_cycle_id, user_id });
      cancelForm();
    }
  };

  return (
    <TimelineItem key={journal.id + '_timeline_item'}>
      <TimelineOppositeContent sx={{ minWidth: '180px' }}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DesktopDatePicker
            required
            name='date'
            label='Date'
            inputFormat='MM/DD/YYYY'
            value={form_data.date}
            onChange={(value) => handleDateChange(value)}
            renderInput={(params) => <TextField variant='filled' {...params} />}
          />
        </LocalizationProvider>
      </TimelineOppositeContent>
      <TimelineSeparator>
        <TimelineDot />
        <TimelineConnector />
      </TimelineSeparator>
      <TimelineContent>
        <form onSubmit={onSubmit}>
          <Box sx={{ display: 'flex' }}>
            <TextField
              name='text'
              size='small'
              variant='outlined'
              placeholder='Add journal entry'
              multiline
              minRows={3}
              maxRows={8}
              value={form_data.text}
              onChange={handleFormDataChange}
              sx={{ width: '100%' }}
            />
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}>
              <IconButton
                variant='contained'
                onClick={() => {
                  cancelForm();
                }}>
                <Close />
              </IconButton>
              <IconButton variant='contained' type='submit'>
                <Check />
              </IconButton>
            </Box>
          </Box>
        </form>
      </TimelineContent>
    </TimelineItem>
  );
};

export default JournalForm;
