// React
import { useSelector } from 'react-redux';
// RTK Query API
import { useGetAncillariesByCycleIdQuery } from 'features/ancillaries/ancillariesAPI';
// Redux
import { selectCycleInfo } from 'features/cycles/cyclesSlice';

// Custom Hook wrapper around useGetAncillariesByCycleIdQuery
export const useGetAncillariesByCycleId = () => {
  const { active_cycle_id } = useSelector(selectCycleInfo);
  const is_authenticated = useSelector((state) => state.auth.is_authenticated);
  const no_auth_ancillaries = useSelector(
    (state) => state.ancillaries.no_auth_ancillaries
  );

  const {
    data: ancillaries,
    isSuccess,
    isLoading,
    isFetching,
  } = useGetAncillariesByCycleIdQuery(active_cycle_id, {
    skip: !is_authenticated || !active_cycle_id,
  });

  return {
    data: is_authenticated ? ancillaries : no_auth_ancillaries,
    isSuccess: is_authenticated ? isSuccess : true,
    isLoading,
    isFetching,
  };
};
