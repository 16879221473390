import { toast, Flip } from 'react-toastify';

export const customToast = (type = 'info', message, auto_close = 2000) => {
  toast[type](message, {
    position: 'bottom-right',
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    autoClose: auto_close,
    hideProgressBar: true,
    transition: Flip,
  });
};
