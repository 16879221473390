import { authAPI } from './authAPI';
import { createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import { customToast } from '../../helpers/customToast';
import Cookies from 'js-cookie';

const initialState = {
  is_authenticated: false,
  user_id: null,
  show_login: false,
  show_signup: true,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    reset: () => initialState,
    closeAuth: (state) => {
      state.show_login = false;
    },
    openLogin: (state) => {
      state.show_login = true;
      state.show_signup = false;
      window.dataLayer.push({
        event: 'login_opened',
        category: 'Auth',
        action: 'OPEN',
        auth: false,
      });
    },
    openSignUp: (state, { payload }) => {
      state.show_login = true;
      state.show_signup = true;
      window.dataLayer.push({
        event: 'sign_up_opened',
        category: 'Auth',
        action: 'OPEN',
        conversion: payload ? payload : 'click',
        auth: false,
      });
    },
    toggleSignUp: (state) => {
      state.show_signup = !state.show_signup;
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(authAPI.endpoints.getUser.matchFulfilled, (state, action) => {
        state.is_authenticated = true;
        state.user_id = action.payload.id;
      })
      .addMatcher(authAPI.endpoints.login.matchRejected, (state, action) => {
        customToast('error', action?.error?.message);
      })
      .addMatcher(authAPI.endpoints.login.matchFulfilled, (state, action) => {
        window.dataLayer.push({
          event: 'login_success',
          category: 'Auth',
          action: 'LOGIN',
          auth: true,
        });
        window.location.reload(false);
      })
      .addMatcher(authAPI.endpoints.signUp.matchRejected, (state, action) => {
        customToast('error', action?.error?.message);
      })
      .addMatcher(authAPI.endpoints.signUp.matchFulfilled, (state, action) => {
        customToast('success', 'Please check your email for verification.');
        state.show_signup = false;
        window.dataLayer.push({
          event: 'sign_up_success',
          category: 'Auth',
          action: 'SIGN_UP',
          auth: false,
        });
      })
      .addMatcher(authAPI.endpoints.logout.matchFulfilled, (state) => {
        for (const i in localStorage) {
          if (i.includes('sb')) localStorage.removeItem(i);
        }
        Cookies.remove('sb-refresh-token');
        Cookies.remove('sb-access-token');
        state.is_authenticated = false;
        state.user_id = null;
        window.dataLayer.push({
          event: 'logout_success',
          category: 'Auth',
          action: 'LOGOUT',
          auth: true,
        });
        window.location.reload(false);
      })
      .addMatcher(authAPI.endpoints.logout.matchRejected, (state) => {
        customToast('error', 'Something went wrong. Please try again.');
      });
  },
});

export const selectAuthInfo = createSelector(
  (state) => state.auth.show_login,
  (state) => state.auth.show_signup,
  (state) => state.auth.is_authenticated,
  (show_login, show_signup, is_authenticated) => {
    return {
      show_login,
      show_signup,
      is_authenticated,
    };
  }
);

export const { reset, closeAuth, toggleSignUp, openLogin, openSignUp } =
  authSlice.actions;

export default authSlice.reducer;
