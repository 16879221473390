// React
import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect, useRef } from 'react';
// MUI
import {
  Box,
  AppBar,
  Toolbar,
  IconButton,
  Button,
  Typography,
  MenuItem,
  Menu,
  Stack,
  Tooltip,
  Divider,
  Avatar,
  Chip,
} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Menu as MenuIcon, Add, ExpandMore } from '@mui/icons-material';
import { dark_theme } from '../lib/theme';
import { ThemeProvider } from '@mui/material/styles';
// RTK Query
import { useGetUserQuery, useLogoutMutation } from '../features/auth/authAPI';
import { useGetCyclesQuery } from '../features/cycles/cyclesAPI';
// Redux
import { openSignUp, openLogin } from 'features/auth/authSlice';
import {
  initiateNewCycle,
  initiateNoAuthCycle,
  setActiveCycle,
  selectCycleInfo,
} from '../features/cycles/cyclesSlice';

function Header() {
  const dispatch = useDispatch();
  // Redux
  const { active_cycle_id } = useSelector(selectCycleInfo);
  // React Query
  const { data: user, isLoading: userLoading } = useGetUserQuery();
  const {
    data: cycles,
    isLoading: cyclesLoading,
    isFetching: cyclesFetching,
  } = useGetCyclesQuery(undefined, { skip: !user });
  const [logout] = useLogoutMutation();
  // Component State
  const is_small_screen = useMediaQuery((theme) =>
    theme.breakpoints.down('sm')
  );
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElNavProfile, setAnchorElNavProfile] = useState(null);
  const appBarRef = useRef(null);

  useEffect(() => {
    if (!active_cycle_id) {
      if (cycles?.length > 0 && !cyclesFetching && !cyclesLoading) {
        dispatch(setActiveCycle(cycles[0].id));
      } else if (user) {
        dispatch(initiateNewCycle());
      } else if (!(userLoading || user)) {
        dispatch(initiateNoAuthCycle());
      }
    }
  }, [user, cycles, userLoading]);

  const onLogout = async () => {
    logout();
  };

  const changeActiveCycle = (cycle_id) => {
    setAnchorElNav(null);
    dispatch(setActiveCycle(cycle_id));
  };

  const handleCycleCreate = () => {
    setAnchorElNav(null);
    dispatch(initiateNewCycle());
  };

  return (
    <Box sx={{ flexGrow: 1, paddingBottom: 0 }}>
      <AppBar position='static' ref={appBarRef}>
        <Toolbar
          sx={{ justifyContent: 'space-between', pl: '20px', pr: '18px' }}
          variant='dense'
          disableGutters>
          <Stack direction='row' alignItems={'center'}>
            <IconButton
              size='large'
              edge='start'
              color='inherit'
              aria-label='menu'
              onClick={(e) => setAnchorElNav(e.currentTarget)}
              sx={{ mr: 0 }}>
              <Tooltip title='Cycles' placement='bottom-start' arrow>
                <MenuIcon fontSize='inherit' />
              </Tooltip>
            </IconButton>

            <Menu
              id='menu-appbar'
              anchorEl={anchorElNav}
              keepMounted
              open={Boolean(anchorElNav)}
              onClose={() => setAnchorElNav(null)}
              MenuListProps={{
                style: {
                  padding: 0,
                },
              }}>
              <Typography variant='h6' align={'center'} p={0.5}>
                Cycles
              </Typography>
              {cycles && <Divider />}
              {!cyclesLoading && cycles && (
                <Box pb={1}>
                  {cycles.map((cycle, index) => (
                    <MenuItem
                      disableGutters
                      key={cycle.id}
                      onClick={() => {
                        changeActiveCycle(cycle.id);
                      }}>
                      <Box
                        sx={{
                          display: 'flex',
                          width: '100%',
                          justifyContent: 'flex-start',
                          alignItems: 'center',
                          px: 1,
                        }}>
                        <Chip size='small' label={(index += 1)} />
                        <Typography variant='inherit' ml={1} noWrap>
                          {cycle.title}
                        </Typography>
                      </Box>
                    </MenuItem>
                  ))}
                </Box>
              )}
              <Box
                p={1}
                pt={0}
                sx={{ display: 'flex', justifyContent: 'center' }}>
                <Button
                  variant='contained'
                  onClick={
                    user
                      ? handleCycleCreate
                      : () => {
                          setAnchorElNav(null);
                          dispatch(openSignUp('create_cycle'));
                        }
                  }
                  endIcon={<Add />}>
                  Create Cycle
                </Button>
              </Box>
            </Menu>

            <Box
              component={'img'}
              sx={{
                display: 'flex',
                height: '21px',
                alignSelf: 'center',
                justifySelf: 'center',
              }}
              src='/just1cycle-white-text.svg'
              alt='title-logo'
            />
          </Stack>

          {!userLoading && user ? (
            <>
              <Tooltip title='Account' placement='bottom-end' arrow>
                <Button
                  variant=''
                  sx={{
                    alignItems: 'center',
                    textTransform: 'lowercase',
                    paddingRight: 0,
                  }}
                  onClick={(e) => setAnchorElNavProfile(e.currentTarget)}>
                  <ExpandMore />
                  {!is_small_screen && (
                    <Typography variant='subtitle1' fontWeight='light'>
                      {user.email.split('@')[0]}
                    </Typography>
                  )}

                  <Avatar
                    alt={user?.email[0].toUpperCase()}
                    src={user?.identities[0]?.identity_data?.avatar_url}
                    sx={{ ml: 1, height: 25, width: 25 }}
                  />
                </Button>
              </Tooltip>

              <Menu
                id='menu-appbar'
                anchorEl={anchorElNavProfile}
                keepMounted
                open={Boolean(anchorElNavProfile)}
                onClose={() => setAnchorElNavProfile(null)}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}>
                {/* <MenuItem onClick={() => console.log('go to profile')}>
                    Profile
                  </MenuItem> */}
                {/* <MenuItem
                  onClick={() => {
                    dispatch(setPrivacyPolicyVisible(true));
                    setAnchorElNavProfile(null);
                  }}>
                  Privacy Policy
                </MenuItem> */}
                <MenuItem onClick={onLogout}>Logout</MenuItem>
              </Menu>
            </>
          ) : (
            <ThemeProvider theme={dark_theme}>
              <Stack direction={'row'} alignItems='center'>
                <Button
                  variant='outlined'
                  onClick={() => dispatch(openLogin())}>
                  Login
                </Button>
                <Button
                  variant='contained'
                  onClick={() => dispatch(openSignUp())}
                  sx={{ ml: 1 }}>
                  Sign Up
                </Button>
              </Stack>
            </ThemeProvider>
          )}
        </Toolbar>
      </AppBar>
    </Box>
  );
}

export default Header;
